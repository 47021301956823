<div class="info-container">
  <h2>{{data.name}}</h2>
  <p *ngIf="data.description">{{data.description}}</p>
  <div class="organization-summary">
    <span>{{data.roleCounts()}}</span>
  </div>
</div>

<div class="groups-container">
  <h3>
    Groups
    <button [hidden]="data.groups.length === 0" class="no-style" (click)="editGroups = !editGroups">
      {{editGroups ? 'done' : 'edit'}}
    </button>
  </h3>
  <div *ngIf="!editGroups && data.groups.length > 0" class="groups-list" fxLayout="column">
    <mat-chip-listbox>
      <mat-chip-option *ngFor="let group of data.groups" (click)="selectGroup(group)">
        {{group.name}}<i class="material-icons">edit</i>
      </mat-chip-option>
    </mat-chip-listbox>
  </div>

  <mat-form-field class="edit-groups" *ngIf="editGroups || data.groups.length === 0">
    <mat-chip-grid #chipListEdit>
      <mat-chip-row *ngFor="let group of data.groups" [removable]="!loading" (removed)="removeGroup(group)">
        {{group.name}}
        <mat-icon matChipRemove [style.opacity]="loading ? '0.2' : null">cancel</mat-icon>
      </mat-chip-row>
      <input placeholder="add/remove groups"
        [matChipInputFor]="chipListEdit"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        matChipInputAddOnBlur
        (matChipInputTokenEnd)="addGroup($event)">
    </mat-chip-grid>
  </mat-form-field>
</div>

<div *ngIf="data.projects.length > 0" class="table-container">
  <h3>Projects</h3>
  <table mat-table [dataSource]="data.projects" class="mat-elevation-z4 table">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Project </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
    <ng-container matColumnDef="interval">
      <th mat-header-cell *matHeaderCellDef> Timestep </th>
      <td mat-cell *matCellDef="let element"> {{element.interval}} </td>
    </ng-container>
    <ng-container matColumnDef="units">
      <th mat-header-cell *matHeaderCellDef> Units </th>
      <td mat-cell *matCellDef="let element"> {{element.units}} </td>
    </ng-container>
    <ng-container matColumnDef="timezone">
      <th mat-header-cell *matHeaderCellDef> Time zone </th>
      <td mat-cell *matCellDef="let element"> {{element.timezone}} </td>
    </ng-container>
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef> Location </th>
      <td mat-cell *matCellDef="let element"> {{element.location}} </td>
    </ng-container>
    <ng-container matColumnDef="isNRT">
      <th mat-header-cell *matHeaderCellDef> NRT </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon [class.is-nrt]="element.isNrt">
          {{element.isNRT ? 'check_circle' : 'clear'}}
        </mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>