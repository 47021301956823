import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResourceGroup } from '../profile.models';

@Component({
  selector: 'app-resource-group-list',
  templateUrl: './resource-group-list.component.html',
  styleUrls: ['./resource-group-list.component.scss']
})
export class ResourceGroupListComponent {

  hideShadow = { top: true, bottom: false };

  constructor(
    public dialogRef: MatDialogRef<ResourceGroupListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ResourceGroup
  ) { }

  public scrollCheck(event): void {
    const element = event.target || event.srcElement || event.currentTarget;
    if (
      element.scrollHeight > element.style.maxHeight &&
      element.scrollHeight > element.clientHeight
    ) {
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.hideShadow.top = false;
        this.hideShadow.bottom = true;
      } else if (element.scrollTop === 0) {
        this.hideShadow.top = true;
        this.hideShadow.bottom = false;
      } else {
        this.hideShadow.top = false;
        this.hideShadow.bottom = false;
      }
    } else {
      this.hideShadow.top = true;
      this.hideShadow.bottom = true;
    }
  }

}
