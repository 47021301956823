import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserService } from '@app/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Project } from '../models';
import { environment } from '@env/environment'

@Component({
  selector: 'app-nav-grid',
  templateUrl: './nav-grid.component.html',
  styleUrls: ['./nav-grid.component.scss']
})
export class NavGridComponent implements OnInit {
  private _products;
  products = [];
  private rxSubs: Subscription[] = [];
  loading = true;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    public router: Router,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon(
      'rain-gauge',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/baseline-rain_gauge-24px-2021.svg')
    ).addSvgIcon(
      'gauge-reports',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/baseline-gauge_reports-24px.svg')
    ).addSvgIcon(
      'alert-manager',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/alert_manager-24px.svg')
    ).addSvgIcon(
      'inundation',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/inundation-24px.svg')
    ).addSvgIcon(
      'watchpoints',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/watchpoints-24px.svg')
    );
  }

  ngOnInit(): void {
    this.http.get(`/assets/products.json`).toPromise().then(res => {
      this._products = res;
      this.rxSubs.push(
        this.userService.project$.subscribe(this.onProjectChange)
      );
    });
  }

  ngOnDestroy(): void {
    this.rxSubs.forEach(s => s.unsubscribe());
  }

  onProjectChange = (proj: Project): void => {
    this.products = proj.products ? this._products.filter(
      prod => {
        const userRole = this.userService.user.role;
        const routeRoles: {[key:string]: number} = {};
        this.router.config.forEach(r => {
          const betaCheck = environment.production && r.data && r.data.betaOnly;
          routeRoles[r.path] = betaCheck ? 0 : r.data ? r.data.role : undefined;
        });
        const link = prod.link.substring(1);
        return proj.products.includes(link) &&
          (this.userService.userGroupHasProductPermission(link) || (isNaN(routeRoles[link]) || userRole <= routeRoles[link]));
      }
    ) : [];
    this.loading = false;
  }
}
