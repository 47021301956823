<div class="list-container" fxLayout="column" *ngIf="alerts?.length">
  <div
    class="alert"
    matRipple
    fxLayout
    fxLayoutAlign="start center"
    *ngFor="let alert of alerts"
    [class.selected]="alert.alertId === outlineId"
    [title]="
      alert.rule.description ? '&quot;' + alert.rule.description + '&quot;' : ''
    "
    (click)="onAlertClick(alert)"
  >
    <mat-icon
      *ngIf="typeStyles && typeStyles[alert.rule.ruleType]"
      [svgIcon]="
        typeStyles[alert.rule.ruleType].svg
          ? typeStyles[alert.rule.ruleType].icon
          : null
      "
      [ngStyle]="{ color: typeStyles[alert.rule.ruleType].color || 'black' }"
      [title]="
        typeStyles[alert.rule.ruleType].group +
        ' - ' +
        typeStyles[alert.rule.ruleType].descriptor
      "
    >
      {{
        typeStyles[alert.rule.ruleType].svg
          ? ''
          : typeStyles[alert.rule.ruleType].icon
      }}
    </mat-icon>
    <div class="alert-text" fxFlex>
      <div class="primary">{{ alert.rule.name }}</div>
      <div class="secondary" fxLayout fxLayoutGap="4px">
        <mat-icon
          inline
          [ngStyle]="{
            color: ['#C62828', '#FFA000', '#43A047'][alert.rule.importance]
          }"
          >warning</mat-icon
        >
        <div fxFlex class="threshold-description">
          {{ alert.rule.thresholdDescription }}
        </div>
        <div
          [title]="
            alert.activation[0].big + ' - ' +
            (alert.activation[1]?.big || 'Now')
          "
        >
          <mat-icon inline *ngIf="alert.activation[1]">event_available</mat-icon>
          {{ alert.activation[0].small }}
        </div>
      </div>
    </div>
    <div class="actions">
      <button
        mat-icon-button
        title="Show Maps notification"
        *ngIf="map && alert.geoJSON"
        (click)="
          alert.alertId === outlineId
            ? removeOutline($event)
            : onShowMapsNotification(alert, $event)
        "
      >
        <mat-icon>{{
          alert.alertId === outlineId ? 'location_off' : 'add_location'
        }}</mat-icon>
      </button>
    </div>
  </div>
</div>
<div
  class="loading"
  fxLayout
  fxLayoutAlign="center center"
  *ngIf="loading || !typeStyles"
>
  <mat-spinner diameter="40"></mat-spinner>
</div>
<div
  class="loading"
  fxLayout
  fxLayoutAlign="center center"
  *ngIf="error"
>
  <mat-icon>error</mat-icon>
</div>
<div *ngIf="!error && !loading && !alerts?.length" class="no-alerts">
  <mat-icon>thumb_up</mat-icon>
  <p>You have no alerts at this time.</p>
</div>

<ng-template #detailsDialog>
  <mat-dialog-content>
    <app-details [alert]="selected" [typeStyles]="typeStyles"></app-details>
  </mat-dialog-content>
</ng-template>
