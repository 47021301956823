import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UserService } from '@app/core';
import { Subscription } from 'rxjs';
import { Project, User } from '@app/shared/models';
import { ResourceGroup } from '../profile.models';
import { MatDialog } from '@angular/material/dialog';
import { ResourceGroupListComponent } from '../resource-group-list/resource-group-list.component';

@Component({
  selector: 'app-profile-element',
  templateUrl: './profile-element.component.html',
  styleUrls: ['./profile-element.component.scss']
})
export class ProfileElementComponent implements OnInit, OnDestroy {

  private rxSubs: Subscription[] = [];
  project: Project;
  user: User;
  resourceGroups: ResourceGroup[] = [];
  @Output() resourceLength = new EventEmitter<string>();
  loading = true;

  constructor(
    public userService: UserService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.rxSubs.push(
      this.userService.project$.subscribe(this.projUpdate),
      this.userService.currentUser.subscribe(u => this.user = u)
    );
  }

  ngOnDestroy(): void {
    this.rxSubs.forEach(s => s.unsubscribe());
  }

  openGroupDialog = (group): void => {
    this.dialog.open(ResourceGroupListComponent, {
      autoFocus: false,
      data: group
    });
  }

  projUpdate = (proj: Project): void => {
    this.project = proj;
    if (!!this.rxSubs[1]) this.rxSubs[1].unsubscribe();
    this.rxSubs[1] = this.userService
      .getResources(proj.symbolicName).subscribe(res => {
        this.resourceGroups = res.map(g => ({
          ...g,
          resources: g.resources.map(resource => {
            const str = resource.url.replace(/(https:\/\/|http:\/\/)/, '');
            const type = resource.type.toLowerCase();
            return {
              ...resource, type,
              tooltip: type === 'file' ?
                str.substring(str.lastIndexOf('/') + 1) :
                (str.length > 30 ? '...' : '') + str.substring(str.length - 30),
            };
          })
      }));
        this.resourceLength.emit(res.length ? '2' : '1');
        this.loading = false;
      });
  }
}
