import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './shared/page-not-found.component';
import { PermissionsGuardService as PermissionsGuard } from '@app/core';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { GraphComponent } from './features/graph/graph.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'sign-in',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [PermissionsGuard],
    data: { title: 'Dashboard' },
  },
  {
    path: 'maps',
    loadChildren: () =>
      import('app/features/maps/maps.module').then((m) => m.MapsModule),
    canActivate: [PermissionsGuard],
  },
  {
    path: 'data-downloads',
    loadChildren: () =>
      import('app/features/data-downloads/data-downloads.module').then(
        (m) => m.DataDownloadsModule
      ),
    canActivate: [PermissionsGuard],
  },
  {
    path: 'hydroplotter',
    loadChildren: () =>
      import('app/features/hydroplotter/hydroplotter.module').then(
        (m) => m.HydroplotterModule
      ),
    canActivate: [PermissionsGuard],
  },
  {
    path: 'gauge-status',
    loadChildren: () =>
      import('app/features/gauge-status/gauge-status.module').then(
        (m) => m.GaugeStatusModule
      ),
    canActivate: [PermissionsGuard],
  },
  {
    path: 'watchpoints',
    loadChildren: () =>
      import('app/features/watchpoints/watchpoints.module').then(
        (m) => m.WatchpointsModule
      ),
    canActivate: [PermissionsGuard],
  },
  {
    path: 'ddf',
    loadChildren: () =>
      import('app/features/ddf/ddf.module').then((m) => m.DDFModule),
    canActivate: [PermissionsGuard],
  },
  {
    path: 'rules-manager',
    loadChildren: () =>
      import('app/features/alert-manager/alert-manager.module').then(
        (m) => m.AlertManagerModule
      ),
    canActivate: [PermissionsGuard],
    data: { role: 1 },
  },
  {
    path: 'forecasts',
    loadChildren: () =>
      import('app/features/forecast/forecast.module').then(
        (m) => m.ForecastModule
      ),
    canActivate: [PermissionsGuard],
  },
  {
    path: 'forecast',
    redirectTo: '/forecasts',
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('app/features/profile/profile.module').then(
        (m) => m.ProfileModule
      ),
  },
  {
    path: 'access-manager',
    loadChildren: () =>
      import('app/features/access-manager/access-manager.module').then(
        (m) => m.AccessManagerModule
      ),
  },
  {
    path: 'alerts',
    loadChildren: () =>
      import('app/features/notifications/notifications.module').then(
        (m) => m.NotificationsModule
      ),
    canActivate: [PermissionsGuard],
  },
  {
    path: 'cal-stats',
    loadChildren: () =>
      import('app/features/cal-stats/cal-stats.module').then(
        (m) => m.CalStatsModule
      ),
    canActivate: [PermissionsGuard],
  },
  {
    path: 'what-if',
    loadChildren: () =>
      import('app/features/what-if/what-if.module').then((m) => m.WhatIfModule),
    canActivate: [PermissionsGuard],
    data: { role: 1 },
  },
  {
    path: 'gauge-reports',
    loadChildren: () =>
      import('app/features/gauge-reports/gauge-reports.module').then(
        (m) => m.GaugeReportsModule
      ),
    canActivate: [PermissionsGuard],
  },
  {
    path: 'graph',
    component: GraphComponent,
    data: { hideNav: true },
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
    data: { title: 'Page Not Found' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
