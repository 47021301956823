import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class MFAService {
    constructor(private http: HttpClient) {}

    requiresOTP = (username: string): Observable<any> => {
        const url = `/api/mfa/${username}/config`
        return this.http.get(url);
    }

    requestOTP = (username: string): Observable<any> => {
        const url = `/api/mfa/${username}/request`;
        return this.http.get(url);
    }

    validateOTP = (username: string, otp: string): Observable<any> => {
        const url = `/api/mfa/${username}/verify/${otp}`;
        return this.http.get(url)
            .pipe(catchError(this.handleError))
    }

    private handleError(error: HttpErrorResponse) {
        console.log("error", error);
        return throwError(() => new Error('Invalid Code'));
      }
}
