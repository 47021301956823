<div *ngIf="!hideNav && !awaitingQueryParams && !embed"
     class="flex-container">
  <div class="logo">
    <button mat-icon-button class="desktop-logo" routerLink="dashboard">
      <mat-icon svgIcon="vlogo" class="vieux" alt="logo" style="width: 32px; height: 32px"></mat-icon>
    </button>
  </div>
  <div class="feature-name">
    {{title}}
  </div>
  <div class="pixels">
    <img src="assets/images/toolbar-pixels.gif" class="pixels" alt="pixels">
  </div>
  <div class="menu-items">
    <div
      *ngIf="!!(userService.currentUser | async)"
      class="menu-items">
      <app-alerts *ngIf="(userService.project$ | async)?.products.includes('alerts')" [project]="userService.project$ | async"></app-alerts>
      <button class="apps"
              mat-icon-button
              [matMenuTriggerFor]="appsMenu"
              title="Applications"
              aria-label="Applications">
        <mat-icon>apps</mat-icon>
      </button>
      <nav-user-menu></nav-user-menu>
    </div>
  </div>
</div>

<mat-menu #appsMenu="matMenu" [overlapTrigger]="false" xPosition="before">
  <app-nav-grid></app-nav-grid>
</mat-menu>
