import * as moment from 'moment-timezone';
import { LngLatBoundsLike } from 'mapbox-gl';

export class Project {
  id?: number;
  name?: string;
  interval?: number;
  isNRT?: boolean;
  description?: string;
  symbolicName?: string;
  units?: string;
  timezone?: string;
  location?: string;
  products?: string[];
  bounds?: LngLatBoundsLike;
  permissions?: Permissions;
  measurement?: Measurement;
  
  getBounds = function(padding = 0): number[][] {
    return [
      [this.bounds[0][0] + padding, this.bounds[0][1] + padding],
      [this.bounds[1][0] + padding, this.bounds[1][1] + padding]
    ];
  };
  getOffset = function(date: Date | number ): string {
    if (this.timezone && date) {
      return moment(date).tz(this.timezone).format('Z');
    }
    return null;
  };
  getUnitConfig = function(type: string): TypeConfig {
    return (this.measurement && this.measurement.typeConfig) ? 
      this.measurement.typeConfig.find(tc => tc.type === type) : {
        type: 'fallback',
        options: [{ id: 'in', abbr: 'in', name: 'inch' }]
      };
  }
}

interface Permissions {
  apps?: string[];
}

export interface Measurement {
  system?: string;
  typeConfig?: TypeConfig[];
}

export interface TypeConfig {
  type?: string;
  options?: TypeOption[];
}

export interface TypeOption {
  id?: string;
  abbr?: string;
  name?: string;
}
