import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent, DisclaimerComponent } from './dashboard.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { ChangelogModule } from '../changelog/changelog.module';
import { ProfileElementModule } from '../profile/element/profile-element.module';
import { FlexLayoutModule } from 'ngx-flexible-layout';

@NgModule({
  declarations: [ DashboardComponent, DisclaimerComponent ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ChangelogModule,
    ProfileElementModule,

    MatGridListModule,
    MatBottomSheetModule,
    MatButtonModule
  ]
})
export class DashboardModule { }
