import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';


@Injectable()
export class Data {
  data: object;
  name: string;
  type: string;
  unit: string;
  series: Array<number>;
  ticks: Array<number>;

  public getTitle(data$: any): string {
    const name: string = data$.displayName;
    return name;
  }

  public getDataSets(data$: any,  aggregate: Array<any>): Array<any> {
    const datePipe = new DatePipe('en-US');
    const dataSetArray = new Array();
    for (const location in data$.pointData) {
      if (location) {
      const baseName = data$.pointData[location].pointData.description;
        for (const typeItem in data$.pointData[location].pointData.seriesData) {
          if (typeItem) {
            const type = data$.pointData[location].pointData.seriesData[typeItem].typeName;
            for (const type2Index in data$.pointData[location].pointData.seriesData[typeItem].seriesData) {
              if (type && aggregate) {
                const type2 = data$.pointData[location].pointData.seriesData[typeItem].seriesData[type2Index].type;
                let yAxisID;
                if (type === 'OUTFLOW' || type === 'INFLOW') {
                  yAxisID = 'FLOW';
                } else if (type === 'ELEVATION' && baseName === 'Harry S Truman Dam') {
                  yAxisID = 'ELEVATION_HST';
                } else {
                  yAxisID = type;
                }
                const typeName = data$.pointData[location].pointData.seriesData[typeItem].seriesData[type2Index].seriesData.displayName;
                const longName = baseName + ' - ' + typeName;
                const dataSetIndex = aggregate.findIndex(function(element) {
                  if (element.dataSet === longName) {
                    return element.aggregation;
                  }
                });
                const agg = aggregate[dataSetIndex];


                for (const agIndex in data$.pointData[location].pointData.seriesData[typeItem].seriesData[type2Index].seriesData
                  .aggregateDataSeries) {
                  if (data$.pointData[location].pointData.seriesData[typeItem].seriesData[type2Index].seriesData
                    .aggregateDataSeries[agIndex].label === agg.aggregation) {
                    const typeData = data$.pointData[location].pointData.seriesData[typeItem].seriesData[type2Index].seriesData
                      .aggregateDataSeries[agIndex].aggregateDataSeries.data.values;

                    const times = data$.pointData[location].pointData.seriesData[typeItem].seriesData[type2Index]
                      .seriesData.aggregateDataSeries[agIndex].aggregateDataSeries.data.times;
                    const typeData2 = new Array();
                    for (let x = 0; x < times.length; x++) {
                      if (type === 'RAINFALL') {
                        if (times[x]) {
                          typeData2.push(Number(typeData[x]));
                        }
                        if (agg.aggregation === 'AUTO' && type2 === 'fcst-disc') {
                          for (let counter = 0; counter < 3; counter++) {
                            typeData2.push(Number(typeData[x]));
                          }
                        } else if (agg.aggregation === 'HOURLY') {
                          for (let counter = 0; counter < 3; counter++) {
                            typeData2.push(Number(typeData[x]));
                          }
                        } else if (agg.aggregation === 'SIX_HOUR') {
                          for (let counter = 0; counter < 23; counter++) {
                            typeData2.push(Number(typeData[x]));
                          }
                        } else if (agg.aggregation === 'DAILY') {
                          for (let counter = 0; counter < 94; counter++) {
                            typeData2.push(Number(typeData[x]));
                          }
                        }
                      } else {
                        typeData2.push({x: datePipe.transform(new Date(times[x]), 'M/d/yy, HH:mm'), y: Number(typeData[x])});
                      }
                    }
                    dataSetArray.push({dataSetIndex: dataSetIndex, data: typeData2, label: longName, yAxisID: yAxisID, lineTension: 0});
                  }
                  }
              } else if (type) {
                const type2 = data$.pointData[location].pointData.seriesData[typeItem].seriesData[type2Index].type;
                  let yAxisID;
                  if (type === 'OUTFLOW' || type === 'INFLOW') {
                    yAxisID = 'FLOW';
                  } else if (type === 'ELEVATION' && baseName === 'Harry S Truman Dam') {
                    yAxisID = 'ELEVATION_HST';
                  } else {
                    yAxisID = type;
                  }
                const typeName = data$.pointData[location].pointData.seriesData[typeItem].seriesData[type2Index].seriesData.displayName;
                const typeData = data$.pointData[location].pointData.seriesData[typeItem].seriesData[type2Index].seriesData
                  .aggregateDataSeries[0].aggregateDataSeries.data.values;
                  const longName = baseName + ' - ' + typeName;
                const times = data$.pointData[location].pointData.seriesData[typeItem].seriesData[type2Index].seriesData
                  .aggregateDataSeries[0].aggregateDataSeries.data.times;
                  const typeData2 = new Array();
                  for (const x in times) {
                    if (x) {
                      if (type === 'RAINFALL') {
                        typeData2.push(Number(typeData[x]));
                        if (type2 === 'fcst-disc') {
                          typeData2.push(Number(typeData[x]));
                          typeData2.push(Number(typeData[x]));
                          typeData2.push(Number(typeData[x]));
                      }
                      } else {
                      typeData2.push({x: datePipe.transform(new Date(times[x]), 'M/d/yy, HH:mm'), y: Number(typeData[x])});
                      }
                    }
                  }
                    dataSetArray.push({dataSetIndex: -1, data: typeData2, label: longName, yAxisID: yAxisID, lineTension: 0});
                  }
                }
              }
            }
          }
        }

    return dataSetArray;
  }

  public getTicks(data$: any): Array<any> {
    const datePipe = new DatePipe('en-US');
    let ticksArray = new Array();
    for (const locationIndex in data$.pointData) {
      if (locationIndex) {
        for (const typeIndex in data$.pointData[locationIndex].pointData.seriesData) {
          if (typeIndex) {
            for (const type2Index in data$.pointData[locationIndex].pointData.seriesData[typeIndex].seriesData) {
              if (!ticksArray) {
                // ticksArray = data$.pointData[location].seriesData[type][type2].aggregateDataSeries.AUTO.data.times;
                ticksArray = data$.pointData[locationIndex].pointData.seriesData[typeIndex].seriesData[type2Index].seriesData
                  .aggregateDataSeries[0].aggregateDataSeries.data.times;
              } else {
                for (const time in data$.pointData[locationIndex].pointData.seriesData[typeIndex].seriesData[type2Index].seriesData
                  .aggregateDataSeries[0].aggregateDataSeries.data.times) {
                  if (!ticksArray.includes(data$.pointData[locationIndex].pointData.seriesData[typeIndex].seriesData[type2Index].seriesData
                    .aggregateDataSeries[0].aggregateDataSeries.data.times[time])) {
                    ticksArray.push(data$.pointData[locationIndex].pointData.seriesData[typeIndex].seriesData[type2Index].seriesData
                      .aggregateDataSeries[0].aggregateDataSeries.data.times[time]);
                  }
                }
              }
            }
          }
        }
      }
    }
    ticksArray.sort((n1, n2) => n1 - n2);
    const fixed = new Array();
    for (let x = 0; x < ticksArray.length; x++) {
      if (ticksArray[x]) {
        fixed.push(datePipe.transform(ticksArray[x], 'M/d/yy, HH:mm'));
      }
    }
    return fixed;
  }

}
