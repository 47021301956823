import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Project, TypeOption } from '@app/shared/models';
import { Apollo, gql } from 'apollo-angular';
import { from, of, Observable, ReplaySubject, BehaviorSubject, forkJoin } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { RadarInfo, RadarShape } from '../forecast/radar-info.model';
import {
  FilterCategory,
  FilterGroup,
  GenericNotification,
  GenericRule,
  RuleType,
  RuleWindowFuture,
  DataType,
  NotificationQuery,
  RuleQuery,
  DurationWindow,
  RuleField,
  FormDefaults,
  RuleMeta,
  ForecastSource,
} from './alert-manager.models';
import { Layer } from 'mapbox-gl';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { environment } from '@env/environment';

interface NWSDetails {
  id: string;
  source: string;
  product: string;
}

interface GetAll {
  ruleTypes: RuleType[];
  rules: GenericRule[];
}

interface DDFData {
  getDDFFrequencyLabels: string[];
  getDDFDurations: number[];
}

interface Response {
  loadRules?: GenericRule[];
  getAll?: GetAll;
  getLayers?: string[];
  getRadars?: RadarInfo[];
  getNotificationTypes?: RuleType[];
  getRuleTypes?: RuleType[];
  getTags?: string[];
  saveTags?: string[];
  loadNotifications?: GenericNotification[];
  getRuleWindowsFuture?: RuleWindowFuture[];
  getPOIPointDataTypes?: DataType[];
  getCollationSources?: ForecastSource[];
  saveRule?: number;
  archiveRule?: boolean;
  activateRule?: boolean;
  saveNotification?: number;
  getNWSForecastDetails?: NWSDetails[];
  sendTestNotification?: boolean;
}

const genericTypesQuery = `Types(project: $project) {
  id group descriptor description
  fields { id name dataType optional options { id label } }
}`;

const ruleMeta = `meta { id name description notificationIds priority tags
  isActive ruleType }`;

const rulesQuery = `loadRules(project: $project archived: false) {
  dataType sourceId sourceList layer idList aggregation threshold thresholdString
  thresholdIsUpperBound probabilityThreshold precipitationMinimum
  frequencyLabel unit duration windowFuture windowPast points ${ruleMeta}
}`;

const complexRulesQuery = `loadRules(project: $project, ruleType: "Complex", archived: false) {
  idList meta { id name description }
}`;

const loadNotification = `loadNotifications(
  project: $project, notificationIds: $ids
) { contacts contactTypes geoJSON timeout meta { id notificationType name } }`;

const rulesWindows = `getRuleWindowsFuture(project: $project) { id name window }`;

const loadSources = `getLayers(projectName: $project)
  getRadars(project: $project) { id name type bounds shapes { id name bounds }
  meta { source units dataType durationHours interval_minutes product
  intervalDisplay }}`;

@Injectable({
  providedIn: 'root',
})
export class AlertManagerService {
  readonly notificationTypes$ = new ReplaySubject<RuleType[]>(1);
  readonly ruleTypes$ = new ReplaySubject<RuleType[]>(1);
  readonly sources$ = new ReplaySubject<mapboxgl.Layer[]>(1);
  readonly tags$ = new ReplaySubject<string[]>(1);
  nwsDetails: NWSDetails[];
  readonly _priorityFilter: FilterCategory =
  {
        title: 'Importance',
        order: 1,
        groups: [
          {
            name: 'Critical',
            icon: 'warning',
            color: '#C62828',
          },
          {
            name: 'Moderate',
            icon: 'warning',
            color: '#FFA000',
          },
          {
            name: 'Low impact',
            icon: 'warning',
            color: '#43A047',
          },
        ],
   };
  readonly filters$ = new BehaviorSubject([this._priorityFilter]);

  constructor(private apollo: Apollo, private http: HttpClient) {
    this.ruleTypes$.pipe(
      mergeMap((types) => this.getAlertTypes(types))
    ).subscribe((typeFilter) => this.updateFilter(typeFilter));

    this.tags$.subscribe((tags) => {
      let tagFilter = this.simpleFilter("Tags", 2, tags);
      this.updateFilter(tagFilter);
    })
  }

  testNotification(
    project: string,
    notificationId: string,
    ruleId: string
  ): Observable<boolean> {
    return this.apollo
      .mutate<Response>({
        variables: { project, notificationId, ruleId },
        mutation: gql`
          mutation (
            $project: String!
            $notificationId: String!
            $ruleId: String
          ) {
            sendTestNotification(
              project: $project
              notificationId: $notificationId
              ruleId: $ruleId
            )
          }
        `,
      })
      .pipe(map(({ data }) => data.sendTestNotification));
  }

  getAll(project: string): Observable<GetAll> {
    return this.apollo
      .query<Response>({
        variables: { project },
        query: gql`query Query($project:String!) {
        getNotification${genericTypesQuery}
        getRule${genericTypesQuery}
        ${rulesQuery} ${rulesWindows}
        getNWSForecastDetails(project: $project) { id source product }
        getTags(project:$project)
      }`,
      })
      .pipe(
        map(({ data }) => {
          this.nwsDetails = data.getNWSForecastDetails;
          const ruleTypes = data.getRuleTypes
            .map((t: RuleType) => {
              if (t.fields && t.fields.length) {
                t.fields = (t.fields as unknown as RuleField[]).reduce(
                  (obj, item) => ((obj[item.id] = item), obj),
                  {}
                ) as { [key: string]: RuleField };
              }

              const typeWindow = data.getRuleWindowsFuture.find(
                (w) => w.id === t.id
              );

              return {
                ...t,
                window: typeWindow ? typeWindow.window * 60000 : null,
                durationWindow: this.getDurationWindow(t.id),
              };
            })
            .sort((a: RuleType, b: RuleType) => {
              if (a.group.toLowerCase() < b.group.toLowerCase()) return -1;
              if (a.group.toLowerCase() > b.group.toLowerCase()) return 1;
              if (a.descriptor.toLowerCase() < b.descriptor.toLowerCase())
                return -1;
              if (a.descriptor.toLowerCase() > b.descriptor.toLowerCase())
                return 1;
              return 0;
            });
          const mapsIndex = data.getNotificationTypes.findIndex(
            (t) => t.id === 'Map'
          );
          if (mapsIndex !== -1) {
            const maps = data.getNotificationTypes[mapsIndex];
            data.getNotificationTypes.splice(mapsIndex, 1);
            data.getNotificationTypes.splice(0, 0, maps);
          }
          this.notificationTypes$.next(data.getNotificationTypes);
          this.ruleTypes$.next(ruleTypes);
          this.tags$.next(data.getTags);
          data.loadRules.sort((a, b) =>
            this.sortAlphabeticallyBy(a.meta, b.meta, 'name')
          );
          return { ruleTypes, rules: data.loadRules.map(rule => {
            if (rule.unit === '%' && !!rule.threshold) {
              rule.threshold = rule.threshold * 100;
            }
            return rule;
          }) };
        })
      );
  }

  private getDurationWindow(id: string): DurationWindow {
    const durationWindowMap: { [key: string]: DurationWindow } = {
      default: { min: 9e5, max: 6 * 3.6e6, step: 9e5 },
      PrevieuxRainfall: { min: 3e5, max: 3.6e6, step: 3e5 },
      HRRR: { min: 3.6e6, max: 15 * 3.6e6, step: 3.6e6 },
    };

    return durationWindowMap[id] || durationWindowMap.default;
  }

  sortAlphabeticallyBy(
    a: { [key: string]: string } | RuleMeta,
    b: { [key: string]: string } | RuleMeta,
    key: string
  ): number {
    const value1 = a[key].toLowerCase();
    const value2 = b[key].toLowerCase();
    if (value1 < value2) return -1;
    if (value1 > value2) return 1;
    return 0;
  }

  updateFilter(category: FilterCategory) {
    let filters = this.filters$.getValue();
    // Remove this cateegory if it already exists
    filters = filters.filter(cat => cat.title != category.title);
    // Add the new category & sort
    filters.push(category);
    filters.sort((a, b) => a.order - b.order);

    this.filters$.next(filters);
  }

  getAlertTypes(ruleTypes: RuleType[]): Observable<FilterCategory> {
    return this.http.get('/assets/alerts-styles.json').pipe(
      map((styles) => {
        const groups: FilterGroup[] = [];
        ruleTypes.forEach((rt) => {
          const group = groups.find((g) => g.name === rt.group);
          if (group) {
            group.subs.push(rt.descriptor);
          } else {
            const defaultStyle = { icon: 'layer', color: '#000' };
            let style = styles[rt.group.toLowerCase()];
            style = style;
            groups.push({
              name: rt.group,
              subs: [rt.descriptor],
              ...defaultStyle,
              ...style,
            });
          }
        });
        return { title: 'Types', order: 0, groups };
      })
    );
  }

  simpleFilter(title: string, order: number, values: string[]): FilterCategory {
      const groups: FilterGroup[] = [];
      values.forEach(value => {
        groups.push({
            name: value,
            subs: [],
            icon: null,
            color: null
        })
      });
      return {title, order, groups};
  }

  saveTags(project: string, tags: string[]): void {
    this.apollo
      .mutate<Response>({
        mutation: gql`
          mutation ($project: String!, $tags: [String]!) {
            saveTags(project: $project, tags: $tags)
          }
        `,
        variables: { project, tags },
      })
      .subscribe(( {data} ) => this.tags$.next(data.saveTags));
  }

  getNotifications(
    project: string,
    ids: string[]
  ): Observable<GenericNotification[]> {
    return this.apollo
      .query<Response>({
        variables: { project, ids },
        query: gql`query Query($project:String! $ids:[String]) {
          ${loadNotification}
        }`,
      })
      .pipe(map(({ data }) => data.loadNotifications));
  }

  loadSources(project: string): Observable<Layer[]> {
    return this.apollo
      .query<Response>({
        query: gql`query Query($project: String!) { ${loadSources} }`,
        variables: { project },
      })
      .pipe(
        map((res) => {
          const sources = [
            ...res.data.getLayers
              .map((l) => {
                const layer = JSON.parse(l);
                if (layer.metadata.layerType === 'inundation') {
                  layer.metadata.markerMode = true;
                }
                return layer;
              })
              .filter((l) =>
                l.metadata.betaOnly ? !environment.production : true
              ),
            ...res.data.getRadars.map((r) => this.prepRadar(r, project)),
          ];

          this.sources$.next(sources);
          return sources;
        })
      );
  }

  getForecastLayer(info: RadarInfo, proj: string, shape: RadarShape): Layer {
    return {
      id: `forecast-${info.id}-${shape.id}`,
      metadata: {
        ...info,
        bounds: shape.bounds,
        markerMode: shape.id === 'Radar',
        symbolicName: shape.id,
        layerType:
          info.type === 'previeux'
            ? 'previeux'
            : (info.meta.source + '').toLowerCase(),
        project: proj,
        mask: this.getMaskURL(info),
        data: { source: { endpoint: shape.endpoint } },
      },
      type: 'fill',
      source: { type: 'geojson' },
    };
  }

  getMaskURL(radar: RadarInfo, proj?: string): string {
    let url: string;
    switch (radar.type) {
      case 'previeux':
        if (!radar.selectedShape) {
          url = `/api/service/map/radar/${radar.id}/rangering`;
        }
        break;

      default:
        if (proj && this.nwsDetails) {
          const nws = this.nwsDetails.find((p) => p.product === radar.id);
          url = `/api/${proj}/nws/slideshow/mask/${nws ? nws.id : 'default'}`;
        }
        break;
    }
    return url;
  }

  private prepRadar(r: RadarInfo, proj: string): Layer {
    const defaultShape =
      r.type === 'previeux'
        ? {
            id: 'Radar',
            name: 'Radar',
            bounds: r.bounds,
            endpoint: this.getForecastURL('Radar', true, proj),
          }
        : {
            id: r.id,
            name: 'NWS Grid (2.5km)',
            bounds: r.bounds,
            endpoint: this.getForecastURL(r.id, false, proj, r.meta.source),
          };

    const layer = this.getForecastLayer(r, proj, defaultShape);
    layer.metadata.shapes = [
      defaultShape,
      ...r.shapes.map((s) => ({
        ...s,
        endpoint: this.getForecastURL(s.id, true, proj),
      })),
    ];

    return layer;
  }

  private getForecastURL(
    id: string,
    isMosaic: boolean,
    proj: string,
    src?: string
  ): string {
    return (
      `${window.location.origin}/api/${proj}/` +
      (isMosaic
        ? `rainvieux/mosaic/${id}/feature`
        : `NWSMap/${id}/${src.toLowerCase()}`) +
      '.json'
    );
  }

  getDDFData(
    project: string,
    layerId: string,
    tableId?: number
  ): Observable<DDFData> {
    return this.apollo
      .query<DDFData>({
        variables: { project, layerId, tableId },
        query: gql`
          query Query($project: String!, $layerId: String!, $tableId: Int) {
            getDDFFrequencyLabels(project: $project, layerId: $layerId)
            getDDFDurations(
              project: $project
              layerId: $layerId
              tableId: $tableId
            )
          }
        `,
      })
      .pipe(map(({ data }) => data));
  }

  getDataTypes(project: string, pointId: string[]): Observable<DataType[]> {
    return this.apollo
      .query<Response>({
        query: gql`
          query Query($project: String!, $pointId: [String!]) {
            getPOIPointDataTypes(project: $project, pointId: $pointId) {
              symbolicName
              displayName
            }
          }
        `,
        variables: { project, pointId },
      })
      .pipe(
        map(({ data }) => {
          const types: DataType[] = [];
          const ids: string[] = [];
          (data.getPOIPointDataTypes as unknown as DataType[][]).forEach(
            (t: DataType[]) =>
              t.forEach((type) => {
                ids.push(type.symbolicName);
                types.push(type);
              })
          );
          const typesSet = Array.from(new Set(ids));
          typesSet.sort();
          return typesSet.map((ts) => types.find((t) => t.symbolicName === ts));
        })
      );
  }

  detailFormDefaults(): Observable<FormDefaults> {
    return this.http.get<FormDefaults>('/assets/alert-rule-form.json');
  }

  getUnitOptions(
    ruleType: string,
    project: Project,
    unitType?: string
  ): TypeOption[] {
    return project.getUnitConfig(
      unitType ||
        {
          watchpointvalue: 'stage',
          watchpointdelta: 'stage',
          inundation: 'stage',
          garr: 'rain',
          gauge: 'rain',
          previeuxrainrate: 'rain-rate',
          previeuxrainfall: 'rain',
          collation: 'rain',
          qpf: 'rain',
          pqpf: 'rain',
          hrrr: 'rain',
          soilmoisturemulti: 'soil-moisture',
          soilmoisturesingle: 'soil-moisture',
        }[ruleType.toLowerCase()]
    ).options;
  }

  getCollationForecasts(
    project: Project,
    mosaic: string
  ): Observable<ForecastSource[]> {
    return this.apollo
      .query<Response>({
        query: gql`
          query Query(
            $project: String!
            $mosaicLayer: String!
          ) {
            getCollationSources(
              project: $project
              mosaicLayer: $mosaicLayer
            ) {
              id
              name
            }
          }
        `,
        variables: {
          project: project.symbolicName,
          mosaicLayer: mosaic
        }
      })
      .pipe(map(({ data }) => {
        const sources = data.getCollationSources;
        sources.sort((a, b) => b.name.localeCompare(a.name)); // PreVieux first
        return sources;
      }));
  }

  getThresholdDescription(rule: GenericRule): string {
    return `${
      rule.probabilityThreshold
        ? rule.probabilityThreshold + '% chance of '
        : ''
    }${
      rule.threshold ||
      rule.frequencyLabel ||
      rule.thresholdString ||
      rule.precipitationMinimum ||
      rule.probabilityThreshold
    } ${rule.meta.ruleType === 'PQPF' ? 'in' : rule.unit || ''}`;
  }

  getNotifReqs(proj: string, notifForm: UntypedFormGroup): Observable<string[]>[] {
    const notifReqs = [];
    Object.keys(notifForm.controls).forEach((key: string) => {
      const notif = notifForm.get(key);
      const id = notif.get('id').value;
      if (id && notifForm.get(key).dirty) {
        const isNew = id.startsWith('-');
        const variables = {
          project: proj,
          notificationId: isNew ? null : id,
          name: notif.get('name').value || Date.now() + '',
          notificationType: key,
          timeout: notif.get('timeout').value,
          contactTypes: null,
        };

        const listKey = key === 'Map' ? 'geoJSON' : 'contacts';

        variables[listKey] = (notif.get('list') as UntypedFormArray).value
          .map((v) => v?.toString())
          .filter((v) => !!v);

        if (key !== 'Map') {
          variables.contactTypes = notif.get('types').value;
        }

        if (variables[listKey].length) {
          notifReqs.push(this.saveNotification(variables, id));
        } else if (!isNew) {
          notifReqs.push(from([[id, 'remove']]));
        }
      }
    });
    return notifReqs;
  }

  archiveRule(project: string, ruleId: string): Observable<boolean> {
    return this.apollo
      .mutate<Response>({
        mutation: gql`
          mutation ($project: String!, $ruleId: String!) {
            archiveRule(project: $project, ruleId: $ruleId)
          }
        `,
        variables: { project, ruleId },
      })
      .pipe(map(({ data }) => data.archiveRule));
  }

  saveRule(variables: RuleQuery): Observable<number> {
    const { unit, threshold } = variables;
    if (unit === '%' && !!threshold) {
      variables.threshold = threshold / 100;
    }

    return this.apollo
      .mutate<Response>({
        variables,
        mutation: gql`
          mutation (
            $project: String!
            $ruleId: String
            $name: String!
            $description: String
            $ruleType: String!
            $notificationIds: [String]!
            $priority: Int!
            $isActive: Boolean!
            $dashboardIcon: String
            $tags: [String]!
            $sourceId: String
            $sourceList: [String]
            $layer: String
            $idList: [String]
            $aggregation: String
            $dataType: String
            $threshold: Float
            $thresholdString: String
            $thresholdIsUpperBound: Boolean
            $probabilityThreshold: Float
            $precipitationMinimum: Float
            $frequencyLabel: String
            $unit: String
            $duration: Long
            $windowFuture: Long
            $windowPast: Long
            $points: [[Float]]
          ) {
            saveRule(
              project: $project
              ruleId: $ruleId
              name: $name
              description: $description
              ruleType: $ruleType
              notificationIds: $notificationIds
              priority: $priority
              isActive: $isActive
              dashboardIcon: $dashboardIcon
              tags: $tags
              sourceId: $sourceId
              sourceList: $sourceList
              layer: $layer
              idList: $idList
              aggregation: $aggregation
              dataType: $dataType
              threshold: $threshold
              thresholdString: $thresholdString
              thresholdIsUpperBound: $thresholdIsUpperBound
              probabilityThreshold: $probabilityThreshold
              precipitationMinimum: $precipitationMinimum
              frequencyLabel: $frequencyLabel
              unit: $unit
              duration: $duration
              windowFuture: $windowFuture
              windowPast: $windowPast
              points: $points
            )
          }
        `,
      })
      .pipe(map(({ data }) => data.saveRule));
  }

  saveNotification(
    variables: NotificationQuery,
    replaceId: string
  ): Observable<[string, string]> {
    return this.apollo
      .mutate<Response>({
        variables,
        mutation: gql`
          mutation (
            $project: String!
            $notificationId: String
            $name: String!
            $notificationType: String!
            $contacts: [String]
            $contactTypes: [String]
            $timeout: Long
            $geoJSON: [String]
            $color: String
            $showTimer: Boolean
          ) {
            saveNotification(
              project: $project
              notificationId: $notificationId
              name: $name
              notificationType: $notificationType
              contacts: $contacts
              contactTypes: $contactTypes
              timeout: $timeout
              geoJSON: $geoJSON
              color: $color
              showTimer: $showTimer
            )
          }
        `,
      })
      .pipe(
        map(({ data }) => [
          replaceId,
          data.saveNotification ? data.saveNotification + '' : '',
        ])
      );
  }
}
