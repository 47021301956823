import { Component, Input } from '@angular/core';
import { GenericAlert, TypeStyles } from '../notifications.models';
import { GenericNotification, Profile } from '@app/features/alert-manager/alert-manager.models';
import { UserService } from '@app/core';
import { AccessManagerService } from '@app/features/access-manager';
import { AlertManagerService } from '@app/features/alert-manager/alert-manager.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent {
  @Input() alert: GenericAlert;
  @Input() typeStyles: TypeStyles;
  @Input() groups: Profile[];
  @Input() users: Profile[];

  importances = ['Critical', 'Moderate', 'Low Impact'];
  
  constructor(
    private alertService: AlertManagerService,
    private userService: UserService,
    private amService: AccessManagerService
  ) {}

  ngOnInit(): void {
    this.groups = this.userService.user.company.groups
      .map((g) => ({
        id: g.id + '',
        name: g.name,
      }))
      .sort((a, b) => this.alertService.sortAlphabeticallyBy(a, b, 'name'));
    this.amService.getUsers().subscribe(
      (users) =>
        (this.users = users
          .map((u) => ({
            id: u.id + '',
            name: `${u.firstName} ${u.lastName}`,
            phone: u.phone,
          }))
          .sort((a, b) => this.alertService.sortAlphabeticallyBy(a, b, 'name')))
    );
  }

  getIcon(notification: GenericNotification, contactIndex: number): string {
    const type = this.getContactType(notification, contactIndex);
    if(type === "GROUP") {
      return "groups";
    }
    if(type === "USER") {
      return "person";
    }
    if(notification.meta.notificationType === "Email") {
      return "email";
    }
    return "phone";
  }

  displayContact(id: string, notification: GenericNotification, contactIndex: number): string {
    const type = this.getContactType(notification, contactIndex);
    if(["GROUP", "USER"].includes(type)) {
      const profiles = type === "GROUP" ? this.groups : this.users;
      const profile = profiles?.find((p) => p.id === id);
      return profile?.name || id;
    }
    return id;
  }

  private getContactType(notification: GenericNotification, contactIndex: number): string {
    return notification.contactTypes[contactIndex];
  }
}
