import { Injectable } from '@angular/core';

@Injectable()
export class ColorService {

  public static getColors(dataSets: any): Array<any> {
    const colorArray: Array<any> = new Array();

    //Set default color for each dataset
    //Most datasets are colored based on data type, but some are
    //  also colored based on the specific Osage watchpoint
    for (const data in dataSets) {
      const pointIsHst = dataSets[data].label.includes('Harry S Truman Dam');
      const colorHst = '#ed72d2';
      const pointIsBennet = dataSets[data].label.includes('Bennet Spring');
      const colorBennet = '#8a3db8';
      if (dataSets[data].label.includes('Observed Elevation')) {
        if (pointIsHst) {
          colorArray.push({backgroundColor: '#fff', borderColor: colorHst});
        } else {
          colorArray.push({backgroundColor: '#0D47A1', borderColor: '#0D47A1'});
        }
      } else if (dataSets[data].label.includes('Observed Discharge')) {
        if (pointIsHst) {
          colorArray.push({backgroundColor: '#fff', borderColor: colorHst});
        } else if (pointIsBennet) {
          colorArray.push({backgroundColor: '#fff', borderColor: colorBennet});
        } else {
          colorArray.push({backgroundColor: '#fff', borderColor: '#000'});
        }
      } else if (dataSets[data].label.includes('Observed Stage')) {
        colorArray.push({backgroundColor: '#0D47A1', borderColor: '#0D47A1'});
      } else if (dataSets[data].label.includes('Upstream Rainfall')) {
        colorArray.push({backgroundColor: '#0D47A1', borderColor: '#0D47A1'});
      } else if (dataSets[data].label.includes('Forecast Discharge')) {
        if (pointIsHst) {
          colorArray.push({backgroundColor: '#fff', borderColor: colorHst});
        } else {
          colorArray.push({backgroundColor: '#fff', borderColor: '#000'});
        }
      } else if (dataSets[data].label.includes('Simulated Elevation')) {
        colorArray.push({backgroundColor: '#fff', borderColor: '#0D47A1'});
      } else if (dataSets[data].label.includes('Natural Valley Elevation')) {
        colorArray.push({backgroundColor: '#4caf50', borderColor: '#4caf50'});
      } else if (dataSets[data].label.includes('Simulated Inflow')) {
        colorArray.push({backgroundColor: '#fff', borderColor: '#0D47A1'});
      } else if (dataSets[data].label.includes('Simulated Local Inflow')) {
        colorArray.push({backgroundColor: '#fff', borderColor: '#03A9F4'});
      } else if (dataSets[data].label.includes('Natural Valley Outflow')) {
        colorArray.push({backgroundColor: '#4caf50', borderColor: '#4caf50'});
      } else if (dataSets[data].label.includes('Simulated Stage')) {
        colorArray.push({backgroundColor: '#0D47A1', borderColor: '#0D47A1'});
      } else if (dataSets[data].label.includes('Natural Valley Stage')) {
        colorArray.push({backgroundColor: '#4caf50', borderColor: '#4caf50'});
      }
    }
    return colorArray;
  }
}
