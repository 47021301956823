import { Organization, Group } from '../';
export class User {
  id?: number;
  name?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  role?: number;
  company?: Organization;
  email?: string;
  phone?: string;
  groups?: Group[];
  picture?: string;
  lastAccessed?: Date;

  isAdmin = function(): boolean {
    return this.role < 2;
  }

  getFullName = function(): string {
    return `${this.firstName} ${this.lastName}`;
  };
  getAccessDate = function(): Date {
    return new Date(this.lastAccessed);
  };
  getFormattedPhone = function(): string {
    const cleaned = this.phone.replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return 'N/A';
  };
  getPhone = function(): number {
    const phoneNum = parseInt(
      this.phone.replace('+1', ''),
      0
    );
    return phoneNum;
  };
  setPhone = function(num: number): string {
    this.phone = '+1' + num;
    return this.phone;
  };

  groupsToString = function(): string {
    if (!this.groups) {
      return 'N/A';
    } else if (this.groups.length < 1) {
      return 'No groups assigned';
    } else {
      return this.groups
        .map(e => e && e.name ? e.name : null)
        .filter(e => e)
        .join(', ');
    }
  };
}
export default User;
