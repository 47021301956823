// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "us-west-2",
  "Auth": {
      "identityPoolId": "us-west-2:a2b25ad2-17aa-4214-ae85-6dea876eceb7",
      "region": "us-west-2",
      "userPoolId": "us-west-2_xgS0QWRRG",
      "userPoolWebClientId": "6q6f1t9b5d14rj9igtk51bg68q"
  },
  "Storage": {
      "AWSS3": {
          "bucket": "platform-user-files",
          "region": "us-west-2"
      }
  }
};

export default awsmobile;
