import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import Auth from '@aws-amplify/auth';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmedValidator } from './password.validator';
import { MatSnackBar } from '@angular/material/snack-bar';
interface DialogData {
  event: any;
}
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: UntypedFormGroup;
  hide = true;
  hidePassword = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DialogData,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    private fb: UntypedFormBuilder
  ) {}
  get nf() {
    return this.changePasswordForm.controls;
  }
  ngOnInit(): void {
    this.changePasswordForm = this.fb.group(
      {
        currentPassword: ['', [Validators.required]],
        password: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: ConfirmedValidator('password', 'confirmPassword'),
      }
    );
  }
  changePassword(form) {
    const oldPassword = form.get('currentPassword').value;
    const newPassword = form.get('password').value;
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, oldPassword, newPassword);
      })
      .then((data) => {
        const msg = 'Password Successfully Changed';
        this.snackBar.open(msg, null, {
          duration: 6000,
        });
        this.dialogRef.close();
      })
      .catch((err) => {
        let msg = err.message || err;
        if (msg === 'Incorrect username or password.') {
          msg = 'Incorrect current password';
        }
        this.snackBar.open(msg, null, {
          duration: 6000,
          panelClass: 'error',
        });
      });
  }
}
