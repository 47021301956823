import {Apollo, gql} from 'apollo-angular';
import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {User, Project, Organization} from '@app/shared/models';
import {LngLatLike} from 'mapbox-gl';
import {map} from 'rxjs/operators';
import {ResourceGroup} from '@app/features/profile/profile.models';
import {POILevelGroup} from '@app/shared/models/poi/POILevelGroup.model';

declare const gtag;

interface Response {
  myUser?: User;
  getResources?: ResourceGroup[];
}

interface POILevelGroupsResponse {
  getPOILevelGroups: POILevelGroup[] | null;
}

@Injectable({providedIn: 'root'})
export class UserService {
  user: User = null;
  readonly currentUser = new ReplaySubject<User>(1);

  project: Project = null;
  readonly project$ = new ReplaySubject<Project>(1);

  readonly POILevelGroups$ = new ReplaySubject<POILevelGroup[]>(1);

  constructor(private apollo: Apollo) {
  }

  isAdmin = (): boolean => this.user.role < 2;

  setUser(user: User): void {
    if (!!user) {
      user = Object.assign(new User(), {...user});
      if (user.company) {
        user.company = Object.assign(new Organization(), user.company);
      }
      gtag('set', {user_id: user.username});
      gtag('event', 'set_org', {
        org: user.company.id,
        internal: user.username.endsWith('.admin') || user.role === 0,
      });
    }
    this.user = user;
    this.currentUser.next(user);
  }

  myUser(refetch = !this.user): Observable<User> {
    return this.apollo
      .query<Response>({
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
        query: gql`
          query Query {
            myUser {
              id
              username
              firstName
              lastName
              email
              role
              picture
              phone
              groups {
                id
                name
                permissions
              }
              company {
                id
                name
                groups {
                  id
                  name
                }
                projects {
                  id
                  name
                  description
                  symbolicName
                  timezone
                  interval
                  location
                  isNRT
                  permissions {
                    apps
                  }
                  bounds {
                    latitude
                    longitude
                  }
                  measurement {
                    system
                    typeConfig {
                      type
                      options {
                        id
                        abbr
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        `,
      })
      .pipe(map((res) => res.data.myUser));
  }

  getResources(project: string): Observable<ResourceGroup[]> {
    return this.apollo
      .query<Response>({
        query: gql`
          query Resources($project: String!) {
            getResources(project: $project) {
              groupName
              resources {
                name
                url
                type
                mimeType
                size
              }
            }
          }
        `,
        variables: {project},
      })
      .pipe(map((res) => res.data.getResources));
  }

  private prepProject = (proj: Project): Project => {
    if (proj['permissions']) {
      if (proj['permissions'].apps.includes('rules-manager')) {
        proj.products = [...proj['permissions'].apps, 'alerts'];
      } else {
        proj.products = proj['permissions'].apps;
      }
      delete proj['permissions'];
    } else proj.products = ['dashboard'];
    if (proj.bounds) {
      proj.bounds = ((proj.bounds as [LngLatLike, LngLatLike]).map((point) =>
        point['longitude'] ? [point['longitude'], point['latitude']] : point
      ) as unknown) as LngLatLike;
    }
    return Object.assign(new Project(), proj);
  };

  setCurrentProject(proj?: Project): void {
    let newProj: Project;
    if (proj) {
      newProj = proj;
    } else if (this.user.company.projects.length) {
      const pid = localStorage.getItem('pid');
      if (!!pid) {
        newProj = this.user.company.projects.find((p) => pid === p.id + '');
        if (!newProj) {
          newProj = this.user.company.projects[0];
        }
      } else newProj = this.user.company.projects[0];
    } else {
      // todo: add error handling
      alert('error getting project');
      return;
    }
    gtag('event', 'set_proj', {proj: newProj.symbolicName});
    this.project = this.prepProject({...newProj});
    this.project$.next(this.project);
    localStorage.setItem('pid', JSON.stringify(this.project.id));

    this.setPOILevelGroups(this.project.symbolicName);
  }

  removeCurrentProject(): void {
    localStorage.removeItem('pid');
    this.setUser(null);
  }

  userGroupHasProductPermission = (product: string): boolean => {
    let groupHasPermission = false;
    this.user?.groups?.forEach(group => {
      if (group.permissions?.includes(product)) {
        groupHasPermission = true;
      }
    });
    return groupHasPermission;
  }

  getPOILevelGroups(project: string): Observable<POILevelGroup[]> {
    return this.apollo
      .query<POILevelGroupsResponse>({
        query: gql`
          query Query(
            $project: String!
          ) {
              getPOILevelGroups(
                project: $project
              ) {
                  id
                  name
                  severity
                  legacyStatus
                  color
              }
            }
        `,
        variables: {project},
      })
      .pipe(map((res) => res.data.getPOILevelGroups ?? null));
  }

  setPOILevelGroups(project: string) {
    this.getPOILevelGroups(project).subscribe({
      next: (POILevelGroups) => {
        if (POILevelGroups !== null) {
          this.POILevelGroups$.next(POILevelGroups);
        }
      }
    });
  }
}
