<mat-grid-list
    [cols]="layouts[breakpoint]?.col"
    [gutterSize]="layouts[breakpoint]?.gutter"
    rowHeight="220">

  <mat-grid-tile class="element-container" [rowspan]="profHeight">
    <app-profile-element (resourceLength)="profHeight = $event"></app-profile-element>
    <!-- <button class="no-style" (click)="">License &amp; Disclaimer</button> -->
  </mat-grid-tile>

  <mat-grid-tile class="element-container" [rowspan]="2">
    <app-changelog></app-changelog>
  </mat-grid-tile>

  <mat-grid-tile class="element-container">
    <div class="element-content" fxLayout="column" fxLayoutAlign="space-between">
      <section>
        <h3>Contact Support</h3>
        <p>Email: <a href="mailto:support@vieuxinc.com">support@vieuxinc.com</a></p>
        <p>Phone: <a href="tel:+14053251818">(405) 325-1818</a></p>
      </section>
      <button class="no-style" (click)="openBottomSheet()">&#9432; Disclaimer</button>
    </div>
  </mat-grid-tile>
</mat-grid-list>