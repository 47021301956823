<div id="profile-container" [class.mobile]="media.isActive('xs')">
  <mat-card appearance="outlined" *ngIf="user" class="current-user">
    <mat-card-header>
      <div mat-card-avatar>
        <button
          mat-icon-button
          disable-ripple
          color="secondary"
          (click)="choosePicture()"
          matTooltip="Update picture"
        >
          <app-avatar></app-avatar>
          <div class="add-image">
            <mat-icon>add</mat-icon>
          </div>
        </button>
        <input
          type="file"
          hidden
          id="upload_picture"
          aria-label="Upload New Picture"
          accept="image/*"
          file-upload
          (change)="openPictureDialog($event)"
        />
      </div>
      <mat-card-title>{{ user.getFullName() }}</mat-card-title>
      <mat-card-subtitle>
        {{ user.email }}
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-divider></mat-divider>
      <mat-list role="list">
        <mat-list-item role="listitem">
          <mat-icon matListItemAvatar>account_box</mat-icon>
          <h4 matListItemTitle>User name</h4>
          {{ user.username }}
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <mat-icon matListItemAvatar>phone</mat-icon>
          <h4 matListItemTitle>Phone number</h4>
            {{ user.getFormattedPhone() }}
            <a [href]="'tel:' + user.phone" *ngIf="user.phone">(call)</a>
        </mat-list-item>

        <mat-divider></mat-divider>
        <mat-list-item style="cursor: pointer" matTooltip="Update Password">
          <button mat-raised-button color="accent" (click)="changePassword()">
            <mat-icon matListItemAvatar>lock</mat-icon> Change Password
          </button>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>
</div>
