// Core Imports
import { Component } from '@angular/core';

// Material Imports
import { MatDialog } from '@angular/material/dialog';

// Local Imports
import { Data } from './data';
import { DataService } from './data.service';

@Component({
  selector: 'app-hydroplotter',
  templateUrl: './hydroplotter.component.html',
  styleUrls: ['./hydroplotter.component.scss'],
  providers: [MatDialog, DataService, Data]
})
export class HydroplotterComponent {
  title = 'HydroVieux';
  dialog: MatDialog;
  chartTitle = 'Lake of the Ozarks';

  public background = '#FAFAFA';
}
