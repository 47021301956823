<div class="shadow top" [hidden]="hideShadow.top"></div>
<div mat-dialog-content (scroll)="scrollCheck($event)">
  <mat-nav-list>
    <a
        mat-list-item
        [href]="resource.url"
        [attr.download]="resource.type === 'file'"
        [matTooltip]="resource.tooltip"
        matTooltipShowDelay="500"
        *ngFor="let resource of data.resources">
      <mat-icon matListIcon>{{resource.type === 'file' ? 'get_app' : 'launch'}}</mat-icon>
      {{resource.name}}
    </a>
  </mat-nav-list>
</div>
<div class="shadow bottom" [hidden]="hideShadow.bottom"></div>
