import { LngLat } from 'mapbox-gl';

export interface Result {
  id: string;
  project?: string;
  name?: string;
  description?: string;
  requested?: number;
  requestedDisplay?: string;
  requestedBy?: string;
  status?: string;
  config?: RunConfig;
}

export interface RunConfig {
  project?: string;
  name: string;
  description?: string;
  solveTime: number;
  adjustType: string;
  adjustValue: number;
  soilMoisture: number;
  source: SourceConfig;
  boundaryConditions?: BCInput[];
}

export interface SourceConfig {
  id: string;
  type: string;
  start?: number;
  end?: number;
  forecast?: number;
  invalid?: boolean;
}

export interface SourceAdjustConfig {
  adjustType: string;
  adjustValue: number;
  invalid?: boolean;
}

export interface BCInput {
  id: string;
  data: BCDataInput;
}

export interface BCDataInput {
  dataType: string;
  times: number[];
  values: number[];
}

export interface RainSourceSelection {
  id: string;
  startTime: number;
  endTime: number;
  adjustments: SourceAdjustments;
}

export interface SoilMoistureSourceSelection {
  id: string;
  time: number;
  adjustments: SourceAdjustments;
}

export interface SourceAdjustments {
  set: number;
  multiply: number;
  add: number;
}

export interface WhatIfHyetograph {
  values: number[];
}

export interface ControlConfig {
  icon: string | (() => string);
  eventName: string;
  title: string;
  svg?: boolean;
}

export interface Option {
  name: string;
  id: string;
}

export interface Options {
  rainSources: [Option]
  boundaryConditions: [Option]
  bopFiles: [Option]
  evapotranspiration: [Option]
  inundation: [Option]
  state: [Option]
}

export interface RainSource {
  id: string;
  name: string;
  type: string;
}

export interface BCData {
  point: BCPoint;
  dataType: BCDataType;
  times: number[];
  values: number[];
  edited: boolean;
  valid: boolean;
}

export enum BCDataType {
  Flow = 'obs-disc',
  Stage = 'obs-stage',
}

export enum BCSourceType {
  Watchpoint = 'WATCHPOINT',  //can load data from a watchpoint
  None = 'NONE',    //no source - can't load data, but can still make a BC
  Model = 'MODEL',    //source depends on model output; shouldn't show up
}

export interface BCPoint {
  id: string;
  name: string;
  lngLat: LngLat;
  sourceType: BCSourceType;
}

export interface BCPreset {
  id: string;
  dataType: string;
  name: string;
  project: string;
  times: number[];
  values: number[];
}

export interface BCType {
  name: string
  preset?: BCPreset
}