import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Project } from '@app/shared/models';
import { Apollo, gql } from 'apollo-angular';
import { AnyLayer } from 'mapbox-gl';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RuleType } from '../alert-manager/alert-manager.models';
import {
  AlertMaker,
  AlertModelAPI,
  GenericAlert,
  IconStyles,
} from './notifications.models';

interface Response {
  getAlertHistory?: {
    alerts: AlertModelAPI[];
  };
  getRuleTypes?: RuleType[];
  getAlert?: AlertModelAPI;
}

const ruleMeta = `meta { id name description notificationIds priority
  isActive ruleType }`;

const rule = `dataType sourceId layer idList aggregation threshold unit points
  thresholdString thresholdIsUpperBound probabilityThreshold frequencyLabel
  precipitationMinimum duration windowFuture windowPast ${ruleMeta}`;

const alertQuery = `alertId alertStart alertEnd acknowledgedBy acknowledgedTime
  rule { ${rule} } notifications { geoJSON contacts contactTypes meta { id notificationType } } forecastTime`;

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private http: HttpClient, private apollo: Apollo) {}

  getAlertHistory(
    project: Project,
    startTime?: number,
    endTime?: number
  ): Observable<GenericAlert[]> {
    return this.apollo
      .query<Response>({
        variables: { project: project.symbolicName, startTime, endTime },
        query: gql`query Query($project:String! $startTime:Long $endTime:Long) {
          getAlertHistory(project:$project startTime:$startTime endTime:$endTime) {
            alerts { ${alertQuery} }
          }
        }`,
      })
      .pipe(
        map(({ data, errors }) => {
          if (data.getAlertHistory) {
            return data.getAlertHistory.alerts
              .map((alert) => AlertMaker.create(alert, project.timezone))
              .sort(this.sortStart);
          }
          if (errors?.length) {
            throw new Error(errors[0].message);
          }
        })
      );
  }

  getAlert(
    alertId: string,
    { symbolicName, timezone }: Project
  ): Observable<GenericAlert> {
    return this.apollo
      .query<Response>({
        variables: { alertId, project: symbolicName },
        query: gql`query Query($project: String!, $alertId: String!) {
          getAlert(project:$project, alertId:$alertId) { ${alertQuery} }
        }`,
      })
      .pipe(map(({ data }) => AlertMaker.create(data.getAlert, timezone)));
  }

  private sortStart = (a: GenericAlert, b: GenericAlert) => {
    if (a.activation[0].time > b.activation[0].time) {
      return -1;
    }
    if (a.activation[0].time < b.activation[0].time) {
      return 1;
    }
    return 0;
  };

  getStyles(): Observable<IconStyles> {
    return this.http.get<IconStyles>('/assets/alerts-styles.json');
  }

  getRuleTypes(project: string): Observable<RuleType[]> {
    return this.apollo
      .query<Response>({
        variables: { project },
        query: gql`
          query Query($project: String!) {
            getRuleTypes(project: $project) {
              id
              group
              descriptor
            }
          }
        `,
      })
      .pipe(map(({ data }) => data.getRuleTypes));
  }

  getLayerStyles(): Observable<{ [key: string]: Partial<AnyLayer> }> {
    return this.http.get<{ [key: string]: AnyLayer }>(
      '/assets/alerts-layer-styles.json'
    );
  }
}
