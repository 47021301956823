<form *ngIf="editGroupForm" [formGroup]="editGroupForm" (ngSubmit)="onSubmit()">

  <div mat-dialog-title fxLayout fxLayoutAlign="space-between center">
    <div>Group Details</div>
    <div>
      <button mat-icon-button mat-dialog-close aria-label="Close Group Details Dialog">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div mat-dialog-content fxLayout="column">
    <div class="error" *ngIf="error">{{error}}</div>
    <mat-form-field>
      <input matInput placeholder="Name" formControlName="name" required>
      <mat-error *ngIf="editGroupForm?.get('name').invalid">
        A name is required.
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <textarea matInput
        placeholder="Description{{loading ? ' loading...' : ''}}"
        formControlName="description"></textarea>
    </mat-form-field>
  </div>

  <div mat-dialog-actions fxLayout fxLayoutAlign="end center">
    <button mat-raised-button (click)="closeDialog()">Cancel</button>
    <button type="submit"
      color="primary"
      [disabled]="loading || editGroupForm?.pristine || editGroupForm?.invalid" mat-raised-button>
      {{loading ? 'Loading' : 'Save'}}
    </button>
  </div>

</form>
