import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserService } from '../user.service';
import { forkJoin, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Project, User } from '@app/shared/models';

@Injectable({ providedIn: 'root' })
export class PermissionsGuardService implements CanActivate {
  constructor(private userService: UserService, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const path = route.url[0].path;
    const role = route.data.role === 0 ? 0 : route.data.role || 2;
    const reqs = [
      this.userService.currentUser.pipe(first(v => !!v)),
      this.userService.project$.pipe(first(v => !!v))
    ];
    return forkJoin(reqs).pipe(
      map((res: [User, Project]) => {
        const user: User = res[0];
        const proj: Project = res[1];
        const noProd = !!proj && proj.products && !proj.products.includes(path);
        if (noProd || (user.role > role && !this.userService.userGroupHasProductPermission(path))) {
          this.router.navigate(['/dashboard']);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
