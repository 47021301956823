<button class="alerts"
    mat-icon-button
    [matMenuTriggerFor]="alerts"
    title="Alerts"
    aria-label="Alerts"
    (click)="onClickAlerts()">
  <mat-icon>notifications</mat-icon>
  <div class="badge" [hidden]="alertCheck > alertLatest"></div>
</button>

<mat-menu #alerts="matMenu" [overlapTrigger]="false" xPosition="before">
  <app-notifications-element [times]="times" [project]="project"></app-notifications-element>
</mat-menu>