import { Apollo, gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import {
  Time,
  LayerMaxTime,
  MaxTimesUpdate,
  AlertsUpdate,
  Project,
} from '../models';
import { UserService } from '@app/core';
import { Globals } from '@app/globals';

import { catchError, map, tap } from 'rxjs/operators';
import { Observable, ReplaySubject, Subject, timer } from 'rxjs';

interface Response {
  maxTimesUpdate?: MaxTimesUpdate;
  notificationsUpdate?: AlertsUpdate;
}

@Injectable({ providedIn: 'root' })
export class TimepickerService {
  readonly uniqueMaxtimes$ = new Subject<LayerMaxTime[]>();
  maxtimes: LayerMaxTime[];
  readonly maxtimes$ = new ReplaySubject<LayerMaxTime[]>(1);
  readonly alerts$ = new ReplaySubject<AlertsUpdate>(1);

  constructor(
    private globals: Globals,
    private userService: UserService,
    private apollo: Apollo
  ) {}

  configCheck(time: Time, momentOnly = false): void {
    if (!this.isDate(time.end)) {
      throw new TypeError(`'end' must be of type 'Date'`);
    }
    if (!momentOnly && !time.realtime && !this.isDate(time.start)) {
      throw new TypeError(`'start' must be of type 'Date'`);
    }
  }

  getPeriods(): { name: string; value: number }[] {
    return this.globals.PERIODS.filter(
      (r) => this.userService.project.interval <= r
    ).map((r) => {
      const period = moment.duration(r, 'minutes');
      return {
        name: period.humanize(),
        value: period.asMinutes(),
      };
    });
  }

  isDate(obj: Date): boolean {
    return Object.prototype.toString.call(obj) === '[object Date]';
  }

  alertsObs$ = (project: string): Observable<AlertsUpdate> =>
    this.apollo
      .subscribe<Response>({
        query: gql`
          subscription Subscription($project: String!) {
            notificationsUpdate(project: $project) {
              importance
              timestamp
            }
          }
        `,
        variables: { project },
      })
      .pipe(
        tap(({ data }) => this.alerts$.next(data.notificationsUpdate)),
        map(({ data }) => data.notificationsUpdate)
      );

  maxtimesObs$ = (project: Project): Observable<LayerMaxTime[]> =>
    this.apollo
      .subscribe<Response>({
        query: gql`
          subscription Subscription($project: String!) {
            maxTimesUpdate(project: $project) {
              layerMaxTimes {
                layerType
                layerName
                maxTime
              }
            }
          }
        `,
        variables: { project: project.symbolicName },
      })
      .pipe(
        catchError(() =>
          timer(0, 1000 * 30).pipe(
            map(() => ({
              data: {
                maxTimesUpdate: {
                  layerMaxTimes: [{ maxTime: Date.now() }] as LayerMaxTime[],
                },
              },
            }))
          )
        ),
        map(({ data }) => {
          const maxes = data.maxTimesUpdate.layerMaxTimes.map(
            (m: LayerMaxTime) => ({
              ...m,
              maxTime: +moment(m.maxTime).seconds(0).milliseconds(0),
              maxText: moment(m.maxTime)
                .tz(project.timezone)
                .format('YYYY/MM/DD HH:mm'),
            })
          );

          // todo: remove when historical watchpoints maxtime works
          if (project.symbolicName === 'sara-tabletop') {
            maxes.push({
              layerName: 'sara-tabletop_watchpoints',
              layerType: 'watchpoints',
              maxTime: 1504155600000,
              maxText: moment(1504155600000)
                .tz(project.timezone)
                .format('YYYY/MM/DD HH:mm'),
            });
          }

          this.maxtimes = this.mergeMaxes(maxes, this.maxtimes);
          this.maxtimes$.next(this.maxtimes);
          this.uniqueMaxtimes$.next(maxes);

          return maxes;
        })
      );

  mergeMaxes = (
    newMaxes: LayerMaxTime[],
    oldMaxes: LayerMaxTime[] = []
  ): LayerMaxTime[] => {
    for (let i = 0; i < newMaxes.length; i++) {
      const index = oldMaxes.findIndex(
        (max) =>
          max.layerName === newMaxes[i].layerName &&
          max.layerType === newMaxes[i].layerType
      );
      if (index >= 0) oldMaxes[index] = newMaxes[i];
      else oldMaxes.push(newMaxes[i]);
    }
    return oldMaxes;
  };

  getMaxInfo = (
    mTimes: LayerMaxTime[],
    type?: string,
    name?: string
  ): LayerMaxTime => {
    const reduceMaxTimes = (p, n) => (n.maxTime > p.maxTime ? n : p);
    mTimes = mTimes && mTimes.length ? mTimes : this.maxtimes;
    if (type) {
      if (name) {
        return mTimes.find((m) => m.layerType === type && m.layerName === name);
      }
      return mTimes.filter((m) => m.layerType === type).reduce(reduceMaxTimes);
    }
    return mTimes.reduce((prev, next) =>
      next.maxTime > prev.maxTime ? next : prev
    );
  };

  filterMax(
    max: LayerMaxTime,
    targets: { layerName: string; layerType: string }[]
  ): boolean {
    const layerIndex = targets.findIndex(
      (t) => t.layerName === max.layerName && t.layerType === max.layerType
    );
    return !!(layerIndex + 1);
  }
}
