import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangelogComponent } from '../changelog/changelog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { NavGridModule } from '@app/shared/';
import { FlexLayoutModule } from 'ngx-flexible-layout';



@NgModule({
  declarations: [ChangelogComponent],
  imports: [
    CommonModule,
    NavGridModule,
    FlexLayoutModule,

    MatIconModule,
    MatListModule
  ],
  exports: [ ChangelogComponent ]
})
export class ChangelogModule { }
