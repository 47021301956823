<div mat-dialog-title fxLayout fxLayoutAlign="space-between center">
  <div>New User</div>
  <div>
    <button mat-icon-button mat-dialog-close>
      <mat-icon aria-label="Close New User Dialog">close</mat-icon>
    </button>
  </div>
</div>
<form [formGroup]="newUser" (ngSubmit)="onSubmit()">
  <div mat-dialog-content (scroll)="scrollCheck($event)">
    <div class="form-container" fxLayout="column">
      <div class="error" *ngIf="error">{{error}}</div>
      <div fxLayout="row wrap" fxLayoutGap="16px">
        <mat-form-field fxFlex="calc(50%-16px)">
          <input type="text" matInput name="first" formControlName="firstName" placeholder="First name" autofocus>
        </mat-form-field>
        <mat-form-field fxFlex="calc(50%-16px)">
          <input type="text" matInput name="last" formControlName="lastName" placeholder="Last name">
        </mat-form-field>
      </div>
      <mat-form-field>
        <input matInput placeholder="Phone" type="tel" formControlName="phone">
        <span matPrefix>+1&nbsp;</span>
        <mat-error *ngIf="newUser.controls['phone'].invalid">
          must be 10 numbers (including area code)
        </mat-error>
      </mat-form-field>
      <mat-form-field hideRequiredMarker>
        <input type="text" matInput name="email" formControlName="email" placeholder="Email address" required >
        <mat-error *ngIf="newUser.get('email').hasError('email')">Please use a valid email address</mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="companies.length > 1" hideRequiredMarker>
        <mat-select placeholder="Organization" formControlName="company" [compareWith]="compareSelected" required>
          <mat-option *ngFor="let co of companies" [value]="co">
            {{co.name}} ({{co.id}})
          </mat-option>
        </mat-select>
        <mat-error *ngIf="newUser.controls['company'].hasError('required')">Please choose an organization</mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="newUser.value.company.groups?.length > 0">
        <mat-select placeholder="Groups" formControlName="groups" [compareWith]="compareSelected" multiple>
          <mat-option *ngFor="let group of newUser.value.company.groups" [value]="group">
            {{group.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field hideRequiredMarker>
        <input type="text" class="username" matInput name="username" formControlName="username" placeholder="User name" required>
        <mat-hint *ngIf="!newUser.get('username').hasError('userChar')">Suggested format: firstname.lastname</mat-hint>
        <mat-error *ngIf="newUser.get('username').hasError('userChar')">Only use letters, numbers, periods, &amp; hyphens</mat-error>
      </mat-form-field>
      <div class="checkbox-container">
        <mat-checkbox formControlName="admin">Administrator</mat-checkbox>
      </div>
    </div>
  </div>
  <div mat-dialog-actions fxLayoutAlign="end center">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button type="submit" [disabled]="loading || newUser.pristine || newUser.invalid" mat-raised-button color="accent">{{loading ? 'LOADING...' : 'Create User'}}</button>
  </div>
</form>
