import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccessManagerService } from '../../access-manager.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User, Group, Project } from '@app/shared/models';
import { Globals } from '@app/globals';
import { UserService } from '@app/core';
import { take } from 'rxjs/operators';

export interface CloseData {
  type: string;
  data: User;
}

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit, AfterViewInit {
  editUser = false;
  showDeleteCheck = false;
  editUserForm: UntypedFormGroup;
  loading = true;
  error: string;
  userChanges;
  groups = [];
  changes;
  isCurrentUser = false;
  isAdmin = false;
  project: Project;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: User,
    public dialogRef: MatDialogRef<UserDetailComponent>,
    public amService: AccessManagerService,
    private userService: UserService,
    public snackBar: MatSnackBar,
    public globals: Globals,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.userService.currentUser.pipe(take(1)).subscribe(user => {
      this.isCurrentUser = this.data.id === user.id;
      this.isAdmin = this.userService.isAdmin();
      this.project = this.userService.project;
      this.getUserInfo();
    });
  }

  ngAfterViewInit(): void {
    this.resizeCheck();
  }

  getUserInfo(): void {
    this.loading = true;
    this.amService.getUserDetail(this.data.id)
      .subscribe(
        (res: User) => {
          this.groups = res.company.groups;
          res.company = { ...this.data.company, ...res.company };
          this.data = { ...this.data, ...res };
          const phone: number = this.data.getPhone();
          this.editUserForm = this.fb.group({
            ...this.data,
            phone: this.fb.control(
              (phone ? phone : ''),
              [
                Validators.min(2002000000),
                Validators.max(9999999999),
                Validators.pattern('^[0-9]+$')
              ]
            ),
            groups: this.fb.control(this.data.groups),
            admin: this.fb.control({
              value: this.data.role < 2,
              disabled: this.isCurrentUser || this.data.role < 1
            })
          });
          this.loading = false;
        },
        this.gqlError
      );
  }

  scrollCheck(event): void {
    const element = event.target || event.srcElement || event.currentTarget;
    if (element.scrollHeight > element.clientHeight) {
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        element.style.boxShadow = 'inset 0 7px 9px -7px rgba(0, 0, 0, 0.3)';
      } else if (element.scrollTop === 0) {
        element.style.boxShadow = 'inset 0 -7px 9px -7px rgba(0, 0, 0, 0.3)';
      } else { element.style.boxShadow = null; }
    }
  }

  resizeCheck(): void {
    setTimeout(() => {
      const element = document.getElementsByClassName('mat-dialog-content')[0] as HTMLElement;
      if (element.scrollHeight <= element.clientHeight) {
        element.style.boxShadow = 'none';
      } else {
        element.style.boxShadow = 'inset 0 -7px 9px -7px rgba(0, 0, 0, 0.3)';
      }
    });
  }

  compareGroups(g1: Group, g2: Group): boolean {
    return g1 && g2 ? g1.id === g2.id : g1 === g2;
  }

  onDelete(): void {
    this.loading = true;
    this.amService.removeUser(this.data.id)
      .subscribe(
        () => this.deleteUserNext(this.data),
        this.gqlError
    );
  }

  deleteUserNext = (response: User): void => {
    this.loading = false;
    this.snack(`User Removed: ${this.data.getFullName()}`);
    this.closeDialog({
      type: 'remove',
      data: response
    });
    this.loading = false;
  }

  toggleEditor = (): void => {
    this.editUser = !this.editUser;
    this.error = '';
    this.resizeCheck();
  }

  onSave(): void {
    this.loading = true;
    const formVals = this.editUserForm.value as User;
    const isAdmin = this.editUserForm.get('admin').value;
    this.changes = {
      id: formVals.id,
      firstName: formVals.firstName,
      lastName: formVals.lastName,
      phone: (formVals.phone ? '+1' : '') + formVals.phone,
      groupIds: formVals.groups.map(g => g.id),
      role: this.data.role > 0 ? (isAdmin ? 1 : 2) : 0
    };
    this.amService.updateUser(this.changes)
      .subscribe(
        this.updateUserNext,
        this.gqlError
    );
  }

  updateUserNext = (response: User): void => {
    if (!isNaN(response.id)) {
      this.data = Object.assign(new User(), {
        ...this.data,
        ...this.changes,
        groups: this.editUserForm.value.groups
      });
      this.snack('User Changed: ' + this.data.getFullName());
      this.loading = false;
      this.closeDialog({
        type: 'edit',
        data: this.data
      });
    }
  }

  closeDialog = (data: CloseData): void => {
    this.dialogRef.close(data);
  }

  gqlError = (err): void => {
    const error = err.graphQLErrors[0];
    this.error = error.exception ? error.exception.message : (error.message || error);
    this.editUserForm.markAsPristine();
    this.snack(`Error: ${this.error}`, true);
    this.loading = false;
  }

  snack(msg: string, error?: boolean): void {
    this.snackBar.open(msg, null, {
      duration: error ? 6000 : 4000,
      panelClass: error ? 'error' : null
    });
  }
}
