import { Component, OnInit } from '@angular/core';
import { Globals } from '@app/globals';
import { AuthService, UserService } from '@app/core';
import { Storage } from '@aws-amplify/storage';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-avatar',
  template: '<img [src]="picture">',
  styles: [`:host { max-width: 500px; border-radius: 50%;
    overflow: hidden; display: block; }
    img { width: 100%; display: block; }`
  ]
})
export class AvatarComponent implements OnInit {
  picture: string = this.globals.PROFILE_IMG;
  rxSubs: Subscription[] = [];

  constructor(
    public globals: Globals,
    private auth: AuthService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.rxSubs.push(
      this.auth.pictureChange$.subscribe(this.getUrl),
      this.userService.currentUser.subscribe(this.updateUser)
    );
  }

  updateUser = (user): void => {
    if (user && user.picture) {
      this.getUrl();
    }
  }

  getUrl = (): void => {
    Storage.get('profile.png', { level: 'protected' })
      .then(
        (url: string) => this.picture = url,
        () => this.picture = this.globals.PROFILE_IMG
      );
  }

}
