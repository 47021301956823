export class GaugeDetail {
  name?: string;
  vaiId?: string;
  clientId?: string;
  feedType?: string;
  grouping?: string;
  sums?: GaugeSum[];
  status?: number;
  timeRaw?: number;
  timeFiltered?: number;
}

export class GaugeSum {
  hours?: number;
  sum?: number;
}

export class SimpleSum {
  id: string;
  value: number;
}
