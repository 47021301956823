import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsElementComponent } from './notifications-element.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { FlexModule } from 'ngx-flexible-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { DetailsModule } from '../details/details.module';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [NotificationsElementComponent],
  imports: [
    CommonModule,
    FlexModule,
    DetailsModule,

    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatDialogModule,
  ],
  exports: [NotificationsElementComponent],
})
export class NotificationsElementModule {}
