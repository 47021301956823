import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from 'ngx-flexible-layout';

// Material imports
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// 3rd Party imports
import { NgChartsModule } from 'ng2-charts';
import { ColorPickerModule } from 'ngx-color-picker';

// Local imports
import { WindowRef } from './window-ref';
import { SettingsDialogComponent, PDFDialogComponent } from './dialog.service';
import { ChartComponent } from './chart/chart.component';
import { HydroplotterComponent } from './hydroplotter.component';
import { RoutingModule } from './routing.module';

@NgModule({
  declarations: [
    ChartComponent,
    SettingsDialogComponent,
    PDFDialogComponent,
    HydroplotterComponent
  ],
  imports: [
    // Angular2
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    HttpClientModule,
    FlexLayoutModule,
    // material
    MatDialogModule,
    MatInputModule,
    MatTableModule,
    MatSelectModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTabsModule,
    MatGridListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    // 3rd Party
    NgChartsModule,
    ColorPickerModule,
    // locals
    RoutingModule
  ],
  providers: [ WindowRef ]
})
export class HydroplotterModule { }

