import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HyetographComponent } from './hyetograph.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from 'ngx-flexible-layout';

@NgModule({
  declarations: [HyetographComponent],
  imports: [CommonModule, MatProgressSpinnerModule, FlexLayoutModule],
  exports: [HyetographComponent],
})
export class HyetographModule {}
