export interface Time {
  realtime?: boolean;
  start?: Date;
  end: Date;
}

export interface LayerMaxTime {
  layerType?: string;
  layerName?: string;
  maxTime: number;
  maxText?: string;
}

export interface MaxTimesUpdate {
  layerMaxTimes?: LayerMaxTime[];
}

export interface AlertsUpdate {
  importance: [number, number, number];
  timestamp: number;
}

export class TimeMaker {
  static create(config: {
    end: string | Date;
    start?: string | Date;
    realtime?: boolean;
  }): Time {
    const end = new Date(config.end),
      start = config.start ? new Date(config.start) : undefined,
      realtime = !!config.realtime;
    return { end, realtime, start };
  }
}
