import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment-timezone';
import { UserService } from '@app/core';
import { Time } from '@app/shared/models';
import { Observable } from 'rxjs';
import { LngLat } from 'mapbox-gl';
import { POITime } from './hydrograph.component';
import { Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class HydrographService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private router: Router,
    private apollo: Apollo
  ) {}

  getRawData(wpId: string, times?: POITime, whatif?: string): Observable<any> {
    const project = this.userService.project.symbolicName;
    let url = `/api/${project}/hydrograph/rawdata?watchpointid=${encodeURIComponent(wpId)}`;
    if (times) {
      if (!times.realtime) {
        url += `&start=${times.start.getTime()}&end=${times.end.getTime()}`;
      }
      if (times.fakeNRT) {
        url += `&fakeNow=${times.fakeNow.getTime()}`;
      }
    } else {
      url += `&whatif=${whatif}`;
    }
    return this.http.get(url);
  }

  getVelocity(wpId: string, times: POITime): Observable<any> {
    const project = this.userService.project.symbolicName;
    const end = times.end.getTime();
    return this.http.get(
      `/api/${project}/velocity/${wpId}/hydrograph.json?time=${end}`
    );
  }

  getSoilMoisture(wpId: string, times: POITime): Observable<any> {
    const project = this.userService.project.symbolicName;
    const start = times.start.getTime();
    const end = times.end.getTime();
    return this.http.get(
      `/api/${project}/poi/hydrograph.json?watchpointid=${encodeURIComponent(wpId)}&start=${start}&end=${end}`
    );
  }

  getInundation(
    { lat, lng }: LngLat,
    times?: Time,
    whatif?: string
  ): Observable<any> {
    const project = this.userService.project.symbolicName;
    const time = times?.end.getTime();
    const url = this.router
      .createUrlTree([`/api/${project}/inundation/hydrograph.json`], {
        queryParams: { long: lng, lat, time, whatif },
      })
      .toString();
    return this.http.get(url);
  }

  getFlasherStatus(wpId: string, times?: Time): Observable<any> {
    // extract args
    const project: string = this.userService.project.symbolicName;
    const dataTypes = 'obs-flash';
    const start: number = times?.start?.getTime();
    const end: number = times?.end.getTime();
    // construct URL
    let url = `/api/${project}/hydrograph/rawdata?watchpointid=${encodeURIComponent(wpId)}&dataTypes=${dataTypes}`;
    if (!!start && !!end) {
      url += `&start=${start}&end=${end}`;
    }
    // send HTTP request
    return this.http.get(url);
  }

  getPointGroups(project: string, pointId: string[], dataTypes: string[]): Observable<any> {
    return this.apollo
      .query<PointGroupsResponse>({
        query: gql`
          query Query($project: String!, $pointId: [String!], $dataTypes: [String!]) {
            getPOIGroupings(project: $project, pointId: $pointId, dataTypes: $dataTypes) {
              groupingName
            }
          }
        `,
        variables: { project, pointId, dataTypes },
      })
      .pipe(map((res) => res.data.getPOIGroupings[0]));
  }

  getChartSettings(config: any): any {
    const currentTime = moment.tz(new Date(), config.timezone);
    const xValues =
      config.isInundation || config.isVelocity
        ? null
        : config.axis.x.tick.values;
    return {
      bindto: config.bindto,
      size: {
        width: config.elWidth,
        height: config.elHeight,
      },
      data: config.data,
      regions: config.regions,
      axis: {
        x: {
          type: 'timeseries',
          localtime: false,
          tick: {
            values: xValues,
            count: Math.round((config.elWidth - 40) / 44),
            fit: config.isInundation,
            format: (d): string =>
              moment.tz(d, config.timezone).format('MM/DD HH:mm'),
            width: 40,
          },
        },
        y: {
          padding: {
            top: config.isInundation ? 10 : 0,
            bottom: 10,
          },
          tick: {
            format: (d): string => d.toFixed(d < 10 ? 2 : d < 100 ? 1 : 0),
            outer: false,
          },
          ...config.axis.y,
        },
      },
      grid: {
        x: {
          show: true,
          lines: config.hideNow
            ? []
            : [
                {
                  value: new Date(),
                  class: 'now-label',
                  text: 'Now: ' + currentTime.format('HH:mm'),
                },
              ],
        },
        y: {
          show: true,
        },
      },
      tooltip: {
        grouped: config.groupTooltip,
        format: {
          title: (d): string =>
            moment.tz(d, config.timezone).format('YYYY/MM/DD HH:mm'),
        },
      },
      point: {
        show: !!config.isBoundaryCondition,
      },
      zoom: {
        enabled: false,
      },
      padding: {
        right: 12,
        left: 34,
        bottom: config.legend ? 8 : 0,
      },
      legend: {
        show: config.legend,
      },
      unload: true,
    };
  }
}

interface PointGroupsResponse {
  getPOIGroupings?: {groupingName: string};
}
