// Core imports
import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef } from '@angular/core';
import { timer } from 'rxjs';
import { DatePipe } from '@angular/common';

// Material imports
import { MatDialog } from '@angular/material/dialog';

// Local imports
import { SettingsDialogComponent } from '../dialog.service';
import { ColorService } from '../color.service';
import { DataObject } from '../data-object';
import { DataService } from '../data.service';
import { Data } from '../data';

// Experiment
import { BaseChartDirective, NgChartsModule } from 'ng2-charts';
import Chart from 'chart.js';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css'],
  providers: [ MatDialog, Data ]
})
export class ChartComponent implements OnInit, AfterViewInit, OnDestroy {
  public lineChartOptions: any = {
    responsive: true,
    tooltips: {
      mode: 'nearest',
      callbacks: {
        title: (tooltipItem, data): string => data.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index].x
      }
    },
    hover: {
      mode: 'average'
    },
    interaction: {
      mode: 'nearest'
    },
    events: ['click', 'mousemove'],
    legend: {
      position: 'bottom',
      labels: {
        font: {
          size: 20
        }
      }
    },
    scales: {
      xAxes: [{
        id: 'default',
        position: 'bottom',
        scaleLabel: {
          display: true,
          labelString: 'Time'
        }
      }],
      yAxes: [{
        id: 'ELEVATION',
        position: 'left',
        scaleLabel: {
          display: true,
          labelString: 'Elevation (ft)'
        }
      },
      {
        id: 'ELEVATION_HST',
        position: 'right',
        scaleLabel: {
          display: true,
          labelString: 'Elevation (ft) (HST)'
        }
      },
      {
        id: 'FLOW',
        position: 'right',
        scaleLabel: {
          display: true,
          labelString: 'Flow (cfs)'
        },
        type: 'linear',
      },
      {
        id: 'RAINFALL',
        position: 'left',
        ticks: {
          beginAtZero: true,
          reverse: true,
          suggestedMax: 0.5
        },
        scaleLabel: {
          display: true,
          labelString: 'Rainfall (in)'
        },
        type: 'linear',
      },
      {
        id: 'STAGE',
        position: 'right',
        scaleLabel: {
          display: true,
          labelString: 'Stage (ft)'
        },
        type: 'linear'
      }]
    },
    //optimization for mobile
    maintainAspectRatio: false,
    aspectRatio: 0.4,
  };
  public lineChartLegend = true;
  public lineChartType = 'bar';
  subscription;
  aggregate: number;
  ticks: any[];
  data$: any;
  @Input() chartTitle: string;
  datePipe = new DatePipe('en-US');
  today: Date = new Date();
  preStartDate: any = this.datePipe.transform(new Date(this.today.getTime() - (1 * 24 * 60 * 60 * 1000)), 'shortDate');
  startDate: any = this.datePipe.transform(new Date(this.preStartDate), 'short');
  preEndDate: any = this.datePipe.transform(new Date(this.today.getTime() + (3 * 24 * 60 * 60 * 1000)), 'shortDate');
  endDate: any = this.datePipe.transform(new Date(this.preEndDate), 'short');
  selectedDataSets: any;
  lineChartColors: Array<any>;
  filteredLineChartColors: Array<any>;
  hide = false;
  original: any[] = [];
  selection: any[] = [];
  chartReady = false;
  data: any[];
  graph: any;

  ready = false;

  custom = false;

  aggregations: any[];

  start: any;
  end: any;
  project = 'osage';
  timeZone = 'US/Central';

  customEnd = false;

  chartData: any;

  hiddenDatasets = [];

  @Output() updateStartDateEvent = new EventEmitter<string>();
  @Output() updateEndDateEvent = new EventEmitter<string>();
  @Output() updateOverallAggregationEvent = new EventEmitter<number>();

  @ViewChild(BaseChartDirective) private _chart;
  @ViewChild('baseChart') private myChart: ElementRef;

  constructor(
      private dialog: MatDialog,
      private datasvc: DataService,
      private _dataFormater: Data ) {}

  ngOnInit(): void {
    this.subscription = timer(0, 5 * 60 * 1000)
      .subscribe(() => {
        this.getData();
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.graph = document.getElementById('baseChart');
  }

   async getData(): Promise<any> {
      this.data$ = await this.datasvc.getData(this.project, new Date(this.startDate).getTime(),
        new Date(this.endDate).getTime(), this.timeZone).toPromise();

     const dataSets = this._dataFormater.getDataSets(this.data$, this.aggregations);
     const ticks = this._dataFormater.getTicks(this.data$);
     this.ticks = await ticks;
     this.start = this.ticks[0];
     this.end = this.ticks[this.ticks.length - 1];

     if (!this.aggregations) {
       this.aggregations = [];
       for (const datum in dataSets) {
         if (datum) {
          this.aggregations.push({dataSet: dataSets[datum].label, aggregation: this.getAggregation(dataSets[datum])});
         }
       }
     }

     this.data = [];

     for (const ds in dataSets) {
       //Check if this dataset was previously marked as hidden
       const hidden = this.hiddenDatasets.includes(parseInt(ds));

       if (ds && this.aggregations) {
          const agg = this.aggregations.find(function(element) {
            if (element.dataSet === dataSets[ds].label) {
              return element.aggregation;
            }
          });
          this.data.push(new DataObject(dataSets[ds].label,
            dataSets[ds].data, dataSets[ds].yAxisID, agg.aggregation !== 'AUTO', agg.style, hidden));
        } else if (ds && !this.aggregations) {
          this.data.push(new DataObject(dataSets[ds].label,
            dataSets[ds].data, dataSets[ds].yAxisID, false, null, hidden));
        }
      }

      if (!this.lineChartColors) {
        this.lineChartColors = ColorService.getColors(this.data);
      }

      this.filteredLineChartColors = this.setFilteredColors(dataSets);

      this.ready = true;

    return await this.data;
  }

  private getAggregation(dataSet: any): string {
    switch (dataSet.yAxisID) {
      case 'FLOW':
        if (dataSet.label.includes('Forecast')) {
          return 'AUTO';
        } else {
          return 'SIX_HOUR';
        }
      case 'ELEVATION':
      case 'ELEVATION_HST':
      case 'RAINFALL':
      case 'STAGE':
      default:
        return 'AUTO';
    }
  }

  private async doRefresh(): Promise<any> {
    // this.getData().then(this._chart.refresh());
    this.ready = false;
    this.getData();
    await this.delay(300000).then(() => {
      this.getData();
    });
  }

  public delay(ms: number): Promise<any> {
    return new Promise(resolve => {
      return setTimeout(resolve, ms);
    });
  }

  public pdfClick(e: any): void {
    if (this.aggregations) {
      const anchor = e.target;
      anchor.href = document.getElementsByTagName('canvas')[0].toDataURL();
      anchor.download = 'test.png';
      // print(anchor.href);
      print();
    }
  }

  public settingsClick(): void {
    if (this.aggregations) {
      const dataSet = [];
      for (let x = 0; x < this.aggregations.length; x++) {
        if (x > -1) {
          dataSet.push({dataSet: this.aggregations[x].dataSet, aggregation: this.aggregations[x].aggregation,
            style: this.aggregations[x].style ?? this.data[x].style, color: this.lineChartColors[x].borderColor});
        }
      }
      const dialogRef = this.dialog.open(SettingsDialogComponent, {
        maxWidth: '95vw',
        maxHeight: '95vh',
        data: {
          dataSets: dataSet,
          colors: this.lineChartColors,
          original: this.original,
          startDate: this.start,
          endDate: this.end
        }
      });

      dialogRef.afterClosed().subscribe(() => {
        if (dialogRef.componentInstance.performUpdate) {
          this.chartReady = false;
          const start = this.startDate;
          const end = this.endDate;
          this.startDate = dialogRef.componentInstance.startDate;
          this.endDate = dialogRef.componentInstance.endDate;
          this.aggregations = dialogRef.componentInstance.dataSet;
          this.lineChartColors = this.setColors(dialogRef.componentInstance.dataSet);


          if (this.startDate !== start || this.endDate !== end) {
            this.custom = true;
          }
          this.doRefresh();
        }
      });
    }
  }

  public setColors(data: any[]): any {
    const colorArray = [];
    const conditions1 = [
      'Observed Elevation',
      'Observed Discharge',
      'Observed Stage'
    ];
    const conditions2 = [
      'Forecast Discharge',
      'Simulated Elevation',
      'Simulated Inflow',
      'Simulated Local Inflow',
      'Natural Valley Outflow'
    ];
    const conditions3 = [
      'Upstream Rainfall',
      'Natural Valley Elevation',
      'Simulated Stage',
      'Natural Valley Stage'
    ];
    for (const item in data) {
      if (conditions1.some(el => data[item].dataSet.includes(el))) {
        colorArray.push({
          backgroundColor: data[item].color,
          borderColor: data[item].color,
          pointBorderColor: data[item].color,
          pointBackgroundColor: '#fff'
        });
      } else if (conditions2.some(el => data[item].dataSet.includes(el))) {
        colorArray.push({
          backgroundColor: '#fff',
          borderColor: data[item].color
        });
      } else if (conditions3.some(el => data[item].dataSet.includes(el))) {
        colorArray.push({
          backgroundColor: data[item].color,
          borderColor: data[item].color
        });
      } else {
        colorArray.push({
          backgroundColor: data[item].color,
          borderColor: data[item].color
        });
      }
    }
    return colorArray;
  }

  public chartClicked(e: any): void {
    this.checkHidingDataset(e);
  }

  public checkHidingDataset(e: any): void {
    //Check if we are clicking a legend item, which will hide that dataset
    //We want to record any datasets that are hidden so we can restore their
    //  hidden status after reloading the data; getting data unhides everything
    //Note that this event happens BEFORE the legend click handler; we would like
    //  to override the legend click handler to avoid having to check if the mouse
    //  is in a hitbox, but doing so doesn't allow us access to this.hiddenDatasets
    if (this._chart !== undefined) {
      const event: PointerEvent = e.event;
      const mouseX = event.offsetX;
      const mouseY = event.offsetY;
      const legend = this._chart.chart.legend;

      //Check each legend item's hitbox to see if we clicked it
      for (let ds = 0; ds < legend.legendHitBoxes.length; ++ds) {
        const hitBox = legend.legendHitBoxes[ds];
        if (mouseX >= hitBox.left && mouseX <= hitBox.left + hitBox.width &&
            mouseY >= hitBox.top && mouseY <= hitBox.top + hitBox.height) {
          //Whatever 'hidden' is, it's shortly about to become the opposite,
          //  since this method comes before the legend click handler
          if (legend.legendItems[ds].hidden) {
            //Unmark dataset as hidden
            const index = this.hiddenDatasets.indexOf(ds);
            if (index > -1) {
              this.hiddenDatasets.splice(index, 1);
            }
          } else {
            //Mark dataset as hidden
            this.hiddenDatasets.push(ds);
          }

          //Only one dataset can be clicked, so stop checking
          return;
        }
      }
    }
  }

  public setFilteredColors(dataSets: any): Array<any> {
    if (!dataSets) {
      return this.lineChartColors;
    } else {
      //Only include colors for datasets that have visible data
      const filteredColors: Array<any> = [];
      for (const d in dataSets) {
        const dataSetIndex = dataSets[d].dataSetIndex;
        if (dataSetIndex !== undefined && dataSetIndex !== -1) {
          filteredColors.push(this.lineChartColors[dataSetIndex]);
        } else {
          filteredColors.push(this.lineChartColors[d]);
        }
      }
      return filteredColors;
    }
  }
}
