import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService, UserService } from '@app/core';
import { User, Project } from '@app/shared/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'nav-user-menu',
  templateUrl: './user-menu.tmpl.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit, OnDestroy {
  user: User;
  projects: Project[] = [];
  activeProj: Project;
  rxSubs: Subscription[] = [];
  loading = true;
  error = false;

  constructor(
    public userService: UserService,
    public auth: AuthService
  ) {}

  ngOnInit(): void {
    this.rxSubs.push(
      this.userService.project$
        .subscribe((proj) => this.activeProj = proj, this.setError),
      this.userService.currentUser.subscribe(this.updateUser, this.setError)
    );
  }

  private setError = () => this.error = true;

  updateUser = (user: User): void => {
    this.user = user;
    if (user) this.projects = user.company.projects;
    this.loading = false;
    this.error = false;
  }

  ngOnDestroy(): void {
    this.rxSubs.forEach(o => o.unsubscribe());
  }
}
