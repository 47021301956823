import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailsComponent } from '../details/details.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule } from 'ngx-flexible-layout';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [DetailsComponent],
  exports: [DetailsComponent],
  imports: [
    CommonModule,
    FlexModule,
    MatIconModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
  ],
})
export class DetailsModule {}
