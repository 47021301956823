<div *ngIf="alert && typeStyles">
  <div fxLayout fxLayoutAlign="start center" fxLayoutGap="16px" class="header">
    <mat-icon
      *ngIf="typeStyles && typeStyles[alert.rule.ruleType]"
      [svgIcon]="
        typeStyles[alert.rule.ruleType].svg
          ? typeStyles[alert.rule.ruleType].icon
          : null
      "
      [ngStyle]="{ color: typeStyles[alert.rule.ruleType].color || 'black' }"
      [title]="
        typeStyles[alert.rule.ruleType].group +
        ' - ' +
        typeStyles[alert.rule.ruleType].descriptor
      "
    >
      {{
        typeStyles[alert.rule.ruleType].svg
          ? ''
          : typeStyles[alert.rule.ruleType].icon
      }}
    </mat-icon>
    <div class="text" fxFlex>
      <h2>Alert Details</h2>
      <p>
        {{ alert.activation[0].big }} -
        {{ alert.activation[1] ? alert.activation[1].big : 'Now' }}
      </p>
    </div>
  </div>
  <table class="alert-info">
    <tbody>
      <tr>
        <th>Title</th>
        <td>{{ alert.rule.name }}</td>
      </tr>
      <tr>
        <th>Description</th>
        <td>"{{ alert.rule.description }}"</td>
      </tr>
      <tr>
        <th>Importance</th>
        <td>
          <mat-icon
            inline
            [ngStyle]="{
              color: ['#C62828', '#FFA000', '#43A047'][alert.rule.importance]
            }"
            >warning</mat-icon
          > {{ importances[alert.rule.importance] }}
        </td>
      </tr>
      <tr>
        <th>Type</th>
        <td>
          {{ typeStyles[alert.rule.ruleType].group }} &#8729;
          {{ typeStyles[alert.rule.ruleType].descriptor }}
        </td>
      </tr>
      <tr *ngIf="alert.rule.sourceDescription">
        <th>Source</th>
        <td>{{ alert.rule.sourceDescription }}</td>
      </tr>
      <tr>
        <th>Threshold</th>
        <td>{{ alert.rule.thresholdDescription }}</td>
      </tr>
      <tr *ngIf="alert.forecastTime">
        <th>Forecast Time</th>
        <td>{{alert.forecastTime.big}}</td>
      </tr>
    </tbody>
  </table>
  <h4>Notifications</h4>
  <mat-accordion multi>
    <mat-expansion-panel *ngFor="let notification of alert.notifications">
      <mat-expansion-panel-header *ngIf="notification.meta.notificationType !== 'Map'">
        <mat-panel-title>{{notification.meta.notificationType}}</mat-panel-title>
        <mat-panel-description *ngIf="notification.contacts">{{notification.contacts.length}} contact{{notification.contacts.length > 1 ? 's' : ''}}</mat-panel-description>
      </mat-expansion-panel-header>
      <div class="data" *ngFor="let contact of notification.contacts; index as $index">
        <mat-icon class="icon" matSuffix>{{getIcon(notification, $index)}}</mat-icon>
        {{displayContact(contact, notification, $index)}}
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<mat-spinner diameter="40" *ngIf="!alert || !typeStyles"></mat-spinner>
