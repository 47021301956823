<button mat-icon-button
    class="avatar-button"
    [matTooltip]="user?.getFullName()"
    [matMenuTriggerFor]="rootMenu">
  <app-avatar *ngIf="user && !error"></app-avatar>
  <mat-icon *ngIf="error && !loading">error</mat-icon>
  <mat-spinner diameter="40" *ngIf="loading && !user"></mat-spinner>
</button>
<mat-menu #rootMenu="matMenu" [overlapTrigger]="false">
  <button class="project" mat-menu-item *ngIf="projects.length < 2" disabled>
    <div>{{activeProj?.name}}</div>
    <span>project</span>
  </button>
  <button class="project" mat-menu-item
    *ngIf="projects.length > 1"
    [matMenuTriggerFor]="projMenu">
    {{activeProj?.name}}
    <span>project</span>
  </button>
  <button mat-menu-item routerLink="profile">Profile</button>
  <button mat-menu-item [matMenuTriggerFor]="amMenu" *ngIf="user?.role < 2">Access Manager</button>
  <button mat-menu-item routerLink="access-manager/users" *ngIf="user?.role > 1">Access Manager</button>
  <button mat-menu-item (click)="auth.signOut()">Sign Out</button>
</mat-menu>
<mat-menu #amMenu="matMenu">
  <button mat-menu-item routerLink="access-manager/users">
    <mat-icon>people</mat-icon>
    Users
  </button>
  <button mat-menu-item routerLink="access-manager/organization{{user?.role < 1 ? 's' : ''}}">
    <mat-icon>business</mat-icon>
    Organization{{user?.role < 1 ? 's' : ''}}
  </button>
</mat-menu>
<mat-menu #projMenu="matMenu">
  <button mat-menu-item
      *ngFor="let proj of user?.company.projects"
      [disabled]="proj.id === activeProj?.id"
      (click)="userService.setCurrentProject(proj)">
    {{proj.name}}
  </button>
</mat-menu>
