<h2 mat-dialog-title>Crop Image</h2>
<mat-dialog-content>
  <div class="cropping-container">
    <image-cropper
        outputType="file"
        [resizeToWidth]="500"
        [roundCropper]="true"
        [maintainAspectRatio]="true"
        [onlyScaleDown]="true"
        [aspectRatio]="1 / 1"
        [hidden]="!cropperReady || loading"
        [imageChangedEvent]="imageChangedEvent"
        
        (imageCropped)="croppedImage = $event.base64"
        (imageLoaded)="cropperReady = true"
        (loadImageFailed)="error('failed to load image')"
    ></image-cropper>
    <div class="loading" *ngIf="loading" fxLayoutAlign="center center">
      <mat-spinner diameter="40"></mat-spinner>
    </div>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions fxLayoutAlign="end center">
  <button mat-stroked-button matDialogClose>Cancel</button>
  <button mat-stroked-button [disabled]="!cropperReady" (click)="savePicture()">Save</button>
</mat-dialog-actions>
