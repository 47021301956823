import { Component, Input } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Organization, Group } from '@app/shared/models';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccessManagerService } from '../../access-manager.service';
import { GroupComponent } from './group/group.component';
import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
})
export class OrganizationComponent {
  @Input() data: Organization;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  loading = false;
  editGroups = false;
  displayedColumns: string[] = [
    'name',
    'interval',
    'timezone',
    'location',
    'isNRT',
  ];

  constructor(
    private amServ: AccessManagerService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  addGroup(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // add the group
    if ((value || '').trim()) {
      this.loading = true;
      const index: number = this.data.groups.push({ name: value.trim() }) - 1;
      this.amServ.createGroup(this.data.id, value.trim()).subscribe({
        next: (id) => {
          this.data.groups[index].id = id;
          this.loading = false;
        },
        error: (err) => {
          this.data.groups.pop();
          this.apolloError(err);
        },
      });
    }

    // clear the input value
    if (input) {
      input.value = '';
    }
  }

  removeGroup(group: Group): void {
    const index = this.data.groups.indexOf(group);

    if (index >= 0) {
      const removedItem = this.data.groups.splice(index, 1)[0];
      this.amServ.deleteGroup(group.id).subscribe({
        error: (err: ApolloError) => {
          this.data.groups.splice(index, 0, removedItem);
          this.apolloError(err);
        },
      });
    }
  }

  selectGroup(group: Group): void {
    this.dialog
      .open(GroupComponent, {
        minWidth: '320px',
        data: group,
      })
      .beforeClosed()
      .subscribe(this.editGroup);
  }

  editGroup = (result): void => {
    if (result && result.type) {
      this.data.groups = this.data.groups.map((g) =>
        g.id === result.data.id ? result.data : g
      );
    }
  };

  apolloError = ({ graphQLErrors, networkError }) => {
    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
      this.snack(`${networkError.message}`, true);
    }

    if (graphQLErrors) {
      graphQLErrors.map((gqlErr: GraphQLError) => {
        console.error(
          `[GraphQL error]: Message: ${
            gqlErr.message
          }, Location: ${JSON.stringify(gqlErr.locations)}, Path: ${
            gqlErr.path
          }`
        );
        if (graphQLErrors.extensions?.status === '405') {
          this.snack(`${gqlErr['exception']?.message}`, true);
        } else {
          this.snack(`${gqlErr.message}`, true);
        }
      });
    }

    this.loading = false;
  };

  snack(msg: string, isError?: boolean): void {
    this.snackBar.open(msg, null, {
      duration: isError ? 6000 : 4000,
      panelClass: isError ? 'error' : null,
    });
  }
}
