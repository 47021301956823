import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { AccessManagerService } from '../../access-manager.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { User, Organization, Group } from '@app/shared/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit, OnDestroy, AfterViewInit {
  rxSubs: Subscription[] = [];
  newUser: UntypedFormGroup;
  loading = true;
  error: string;
  companies: Organization[] = [];
  groups: Group[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private amService: AccessManagerService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<NewUserComponent>
  ) {}

  ngOnInit(): void {
    this.newUser = this.fb.group({
      username: ['', [Validators.required, this.userCharValidator]],
      firstName: [''],
      lastName: [''],
      email: ['', [Validators.email, Validators.required]],
      phone: ['', [Validators.min(2002000000), Validators.max(9999999999)]],
      company: ['', Validators.required],
      groups: [[]],
      admin: [false]
    });
    this.amService.newUserFormInfo().toPromise()
      .then(this.loadCompanies, this.gqlError);

    this.rxSubs.push(
      this.newUser.get('firstName').valueChanges
        .subscribe((v) => this.onNameChange('firstName', v)),
      this.newUser.get('lastName').valueChanges
        .subscribe((v) => this.onNameChange('lastName', v))
    );
  }

  ngOnDestroy(): void { this.rxSubs.forEach(s => s.unsubscribe()); }

  ngAfterViewInit(): void { this.resizeCheck(); }

  onNameChange = (control: string, name: string): void => {
    if (name.startsWith(' ')) {
      this.newUser.get(control).setValue(name.slice(1));
    }
    this.updateUsername();
  }

  onUsernameChange = (value: string): void => {
    const username = this.newUser.get('username');
    if (/[A-Z]/g.test(value)) { username.setValue(value.toLowerCase()); }
  }

  updateUsername = (): void => {
    const usernameCtrl = this.newUser.get('username');
    if (usernameCtrl.pristine) {
      const first = this.newUser.get('firstName').value
        .replace(/[^A-Za-z]/g, '').toLowerCase();
      const last = this.newUser.get('lastName').value
        .replace(/[^A-Za-z]/g, '').toLowerCase();
      if (!!first && !!last) { usernameCtrl.setValue(`${first}.${last}`); }
    }
  }

  loadCompanies = (res): void => {
    if (res.length > 1) {
      this.companies = (res as any[]).sort(
        (a, b) => (a.name > b.name) ? 1 : ( (b.name > a.name) ? -1 : 0 )
      );
    } else {
      this.companies = res;
      this.newUser.get('company').setValue(res[0]);
    }
    this.loading = false;
  }

  scrollCheck(event): void {
    const element = event.target || event.srcElement || event.currentTarget;
    if (element.scrollHeight > element.clientHeight) {
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        element.style.boxShadow = 'inset 0 7px 9px -7px rgba(0, 0, 0, 0.3)';
      } else if (element.scrollTop === 0) {
        element.style.boxShadow = 'inset 0 -7px 9px -7px rgba(0, 0, 0, 0.3)';
      } else { element.style.boxShadow = null; }
    }
  }

  resizeCheck(): void {
    setTimeout(() => {
      const element = document.getElementsByClassName('mat-mdc-dialog-container')[0] as HTMLElement;
      if (element.scrollHeight <= element.clientHeight) {
        element.style.boxShadow = 'none';
      } else {
        element.style.boxShadow = 'inset 0 -7px 9px -7px rgba(0, 0, 0, 0.3)';
      }
    });
  }

  compareSelected(s1, s2): boolean {
    return s1 && s2 ? s1.id === s2.id : s1 === s2;
  }

  userCharValidator(control: UntypedFormControl): ValidationErrors {
    return /[^A-Za-z0-9\.\-]/g.test(control.value) ? { 'userChar': true } : null;
  }

  onSubmit(): void {
    this.loading = true;
    this.newUser.markAsPristine();
    const val = this.newUser.value;
    const user = Object.assign(new User(), {
      username: val.username,
      email: val.email,
      phone: val.phone ? '+1' + val.phone : '',
      firstName: val.firstName,
      lastName: val.lastName,
      company: val.company.id,
      groups: val.groups.map(g => g.id),
      role: val.admin ? 1 : 2
    });

    this.amService.inviteUser(user)
      .subscribe({
        next: (res) => {
          this.dialogRef.close({
            type: 'new',
            data: { ...user, ...res,
              company: Object.assign(new Organization(), val.company)
            }
          });
          this.loading = false;
          this.snack(`User created: ${user.getFullName()}`);
        },
        error: this.gqlError,
    });
  }

  gqlError = (err: Error): void => {
    console.error(err);
    this.error = err.message;
    this.snack(this.error, true);
    this.loading = false;
  }

  snack(msg: string, error?: boolean): void {
    this.snackBar.open(msg, null, {
      duration: 4000,
      panelClass: error ? 'error' : null
    });
  }
}
