import { Injectable, Output } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subject } from 'rxjs';

@Injectable()
export class SidenavService {
  private sidenav: MatSidenav;
  public menuItems$ = new Subject<any>();

  public setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }

  public toggle(): void {
    this.sidenav.toggle();
  }

  public setMenu(menu: any) {
    this.menuItems$.next(menu);
  }
}
