export class DataObject {
  label: string;
  data: Array<any>;
  yAxisID: string;
  lineTension: number;
  showLine: boolean;
  display: boolean;
  pointStyle: string;
  pointBorderColor: any;
  pointBorderWidth: number;
  pointBackgroundColor: any;
  pointRadius: number;
  borderColor: any;
  borderWidth: number;
  borderDash: Array<number>;
  fill: boolean;
  type: string;
  xAxisID: string;
  backgroundColor: string;
  pointHoverBackgroundColor: any;
  pointHoverBorderColor: any;
  style: string;
  barPadding: number;
  steppedLine: string | boolean;
  hidden: boolean;

  constructor(label: string, series: Array<any>, yAxis: string, aggregation: boolean, style: string, hidden: boolean) {
    this.label = label;
    this.data = series;
    this.yAxisID = yAxis;
    this.style = style;
    this.showLine = this.getStyle(label, aggregation, style);
    this.fill = false;
    this.display = true;
    this.hidden = hidden;
  }

  private getStyle(label: string, aggregation: boolean, style: string) {
    this.steppedLine = aggregation;
    if (style) {
      switch (style.toUpperCase()) {
        case 'DASHED_LINE':
          this.lineTension = 0;
          this.borderColor = '#000';
          this.borderWidth = 3;
          this.borderDash = [10, 10];
          this.pointRadius = 0;
          this.type = 'line';
          return true;
        case 'CIRCLE':
          this.pointStyle = 'circle';
          this.lineTension = 0;
          this.pointBorderColor = '#000';
          this.pointBorderWidth = 2;
          this.pointRadius = 5;
          this.type = 'line';
          return true;
        case 'TRIANGLE':
          this.pointStyle = 'triangle';
          this.lineTension = 0;
          this.pointBorderColor = '#000';
          this.pointBorderWidth = 2;
          this.pointRadius = 5;
          this.type = 'line';
          return true;
        case 'BAR':
          this.lineTension = 0;
          this.borderColor = '#0D47A1';
          this.borderWidth = 1;
          this.pointRadius = 5;
          this.barPadding = 0;
          this.type = 'bar';
          return false;
        case 'SOLID_LINE':
        default:
          this.lineTension = 0;
          this.borderColor = '#0D47A1';
          this.borderWidth = 5;
          this.pointRadius = 0;
          this.type = 'line';
          return true;
      }

    } else {
      //Set default styling for each dataset
      //Most datasets are styled based on data type, but some are
      //  also colored based on the specific Osage watchpoint
      const pointIsHst = label.includes('Harry S Truman Dam');
      const pointIsBennet = label.includes('Bennet Spring');
      
      if (label.includes('Observed Elevation') && aggregation !== true) {
        this.borderColor = '#0D47A1';
        this.borderWidth = 5;
        this.pointRadius = 0;
        this.type = 'line';
        this.style = 'solid_line';
        return true;
      } else if (label.includes('Observed Elevation') && aggregation === true) {
        this.lineTension = 0;
        this.borderColor = '#0D47A1';
        this.borderWidth = 5;
        this.pointRadius = 0;
        this.type = 'line';
        this.style = 'solid_line';
        return true;
      } else if (label.includes('Observed Discharge')) {
        if (aggregation !== true) {
          this.borderColor = '#000';
          this.borderWidth = 5;
          this.pointRadius = 0;
          this.type = 'line';
          this.style = 'solid_line';
        } else {
          this.lineTension = 0;
          this.borderColor = '#000';
          this.borderWidth = 5;
          this.pointRadius = 0;
          this.type = 'line';
          this.style = 'solid_line';
        }
        if (pointIsHst) {
          this.borderDash = [10, 10];
          this.style = 'dashed_line';
        }
        if (pointIsBennet) {
          this.borderWidth = 3;
        }
        return true;
      } else if (label.includes('Observed Stage') && aggregation !== true) {
        this.borderColor = '#0D47A1';
        this.borderWidth = 5;
        this.pointRadius = 0;
        this.type = 'line';
        this.style = 'solid_line';
        return true;
      } else if (label.includes('Observed Stage') && aggregation === true) {
        this.lineTension = 0;
        this.borderColor = '#0D47A1';
        this.borderWidth = 5;
        this.pointRadius = 0;
        this.type = 'line';
        this.style = 'solid_line';
        return true;
      } else if (label.includes('Upstream Rainfall')) {
        this.lineTension = 0;
        this.borderColor = '#0D47A1';
        this.borderWidth = 5;
        this.pointRadius = 0;
        this.type = 'bar';
        this.style = 'bar';
        return false;
      } else if (label.includes('Forecast Discharge')) {
        this.lineTension = 0;
        this.borderColor = '#000';
        this.borderWidth = 3;
        this.borderDash = [10, 10];
        this.pointRadius = 0;
        this.type = 'line';
        this.style = 'dashed_line';
        return true;
      } else if (label.includes('Simulated Elevation')) {
        this.lineTension = 0;
        this.borderColor = '#0D47A1';
        this.borderWidth = 3;
        this.borderDash = [10, 10];
        this.pointRadius = 0;
        this.type = 'line';
        this.style = 'dashed_line';
        return true;
      } else if (label.includes('Natural Valley Elevation')) {
        this.lineTension = 0;
        this.borderColor = '#1B5E20';
        this.borderWidth = 5;
        this.pointRadius = 0;
        this.type = 'line';
        this.style = 'solid_line';
        return true;
      } else if (label.includes('Simulated Inflow')) {
        this.lineTension = 0;
        this.borderColor = '#0D47A1';
        this.borderWidth = 3;
        this.borderDash = [10, 10];
        this.pointRadius = 0;
        this.type = 'line';
        this.style = 'dashed_line';
        return true;
      } else if (label.includes('Natural Valley Outflow')) {
        this.lineTension = 0;
        this.borderColor = '#1B5E20';
        this.borderWidth = 3;
        this.borderDash = [10, 10];
        this.pointRadius = 0;
        this.type = 'line';
        this.style = 'dashed_line';
        return true;
      } else if (label.includes('Simulated Local Inflow')) {
        this.lineTension = 0;
        this.borderColor = '#03A9F4';
        this.borderWidth = 3;
        this.borderDash = [10, 10];
        this.pointRadius = 0;
        this.type = 'line';
        this.style = 'dashed_line';
        return true;
      } else if (label.includes('Simulated Stage')) {
        this.lineTension = 0;
        this.borderColor = '#0D47A1';
        this.borderWidth = 3;
        this.pointRadius = 0;
        this.type = 'line';
        this.style = 'solid_line';
        return true;
      } else if (label.includes('Natural Valley Stage')) {
        this.lineTension = 0;
        this.borderColor = '#1B5E20';
        this.borderWidth = 3;
        this.pointRadius = 0;
        this.type = 'line';
        this.style = 'solid_line';
        return true;
      } else {
        this.lineTension = 0;
        this.type = 'line';
        this.style = 'solid_line';
        return true;
      }
    }
  }

  get() {
    return this;
  }
}
