import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import {
  PermissionsGuardService,
  AuthService,
  SidenavService,
  UserService,
} from '@app/core';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  exports: [
    HttpClientModule,
    FlexLayoutModule,
  ],
  providers: [
    PermissionsGuardService,
    AuthService,
    SidenavService,
    UserService,
  ]
})
export class CoreModule {
  constructor ( @Optional() @SkipSelf() parentModule: CoreModule ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
