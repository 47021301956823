import { Component } from '@angular/core';

@Component({
  selector: 'app-404',
  template: `
    <article>
      <h4>Oops!</h4>
      <div>We can't seem to find the page you're looking for.</div>
    </article>
  `
})

export class PageNotFoundComponent { }
