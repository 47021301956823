import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-settings-dialog',
  template: `
  <div class="container">
    <div class="row">
      <div class="row">
        <strong>Start Date:</strong>
        &nbsp;<input matInput [(ngModel)]="startDate" placeholder="Start Date" (focusout)="checkValidDates()">
        &nbsp;<mat-error>{{startDateErrorMessage}}</mat-error>
      </div>
      <p>
      <div class="row">
        <strong>End Date:</strong>
        &nbsp;&nbsp;&nbsp;<input matInput [(ngModel)]="endDate" placeholder="End Date" (focusout)="checkValidDates()">
        &nbsp;<mat-error>{{endDateErrorMessage}}</mat-error>
      </div>
    </div>
    <p>
    <table>
      <tr style="text-align:left">
        <th>Location</th>
        <th>Aggregation</th>
        <th>Point Style</th>
        <th>Color</th>
      </tr>
      <tr *ngFor="let datum of dataSet">
        <td style="padding-right:16px">{{datum.dataSet}}</td>
        <td><mat-select placeholder="Aggregation" [(ngModel)]="datum.aggregation" style="width:150px">
              <mat-option *ngFor="let option of aggOptions" [value]="option.value">
                {{ option.viewValue }}
              </mat-option>
            </mat-select>
        </td>
        <td><mat-select placeholder="Style" [(ngModel)]="datum.style" style="width:150px">
              <mat-option *ngFor="let option of styleOptions" [value]="option.value">
                {{ option.viewValue }}
              </mat-option>
            </mat-select>
        </td>
        <td>
          <input [(colorPicker)]="datum.color" [style.background]="datum.color" size="5" />
        </td>
      </tr>
    </table>
    <p>
    <button mat-raised-button (click)="submit()">Submit</button>
  </div>
  `,
})
export class SettingsDialogComponent {
  original: any;
  dataSet: Array<any>;
  ogDataSets: Array<any>;
  startDate: Date;
  endDate: Date;
  aggregation: number;
  colors: Array<any>;
  startDateErrorMessage: string;
  endDateErrorMessage: string;
  performUpdate: boolean;
  aggOptions = [
    {value: 'AUTO', viewValue: 'None'},
    {value: 'HOURLY', viewValue: '1 Hour'},
    {value: 'SIX_HOUR', viewValue: '6 Hours'},
    {value: 'DAILY', viewValue: 'Daily'},
  ];
  styleOptions = [
    {value: 'solid_line', viewValue: 'Line'},
    {value: 'dashed_line', viewValue: 'Dashed Line'},
    {value: 'circle', viewValue: 'Circle'},
    {value: 'triangle', viewValue: 'Triangle'},
    {value: 'bar', viewValue: 'Bar'}
  ];

  constructor(
    public dialogRef: MatDialogRef<SettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.ogDataSets = data.dataSets;
      this.original = data.original;
      this.startDate = data.startDate;
      this.endDate = data.endDate;
      this.aggregation = data.aggregation;
      this.dataSet = data.dataSets;
      this.colors = data.colors;
    }

    getData() {
      const selectors: Array<any> = new Array<any>();
      if (this.ogDataSets.length <= 0) {
        for (const ds in this.original) {
          if (ds) {
            selectors.push({label: this.original[ds], display: true});
          }
        }
      } else {
        for (const ds in this.ogDataSets) {
          if (ds) {
            selectors.push({label: this.ogDataSets[ds], display: true });
          }
        }
        for (const ds in this.original) {
          if (!this.ogDataSets.includes(this.original[ds])) {
            selectors.push({label: this.original[ds], display: false});
          }
        }
      }
      return selectors;
    }

    submit() {
      if (this.checkValidDates()) {
        this.performUpdate = true;
        this.dialogRef.close();
        const returnArray: Array<any> = new Array<any>();

        for (const ds in this.dataSet) {
            returnArray.push(this.dataSet[ds]);
        }

        return {startDate: this.startDate, endDate: this.endDate, aggregation: this.aggregation,
          dataSets: returnArray};
      }
    }

    checkValidDates (): boolean {
      //Reset error messages
      this.startDateErrorMessage = null;
      this.endDateErrorMessage = null;

      //Validate the start and end dates
      const start = new Date(this.startDate);
      const end = new Date(this.endDate);
      const startIsValid = this.isValidDate(start);
      const endIsValid = this.isValidDate(end);

      //Update error message
      if (!startIsValid) {
        this.startDateErrorMessage = "The start date is invalid.";
      }
      if (!endIsValid) {
        this.endDateErrorMessage = "The end date is invalid.";
      }

      if (startIsValid && endIsValid) {
        //Both dates are valid; make sure start comes before end
        if (start.getTime() < end.getTime()) {
          return true;
        } else {
          this.endDateErrorMessage = "The end date must be after the start date.";
          return false;
        }
      } else {
        //One or both dates are invalid
        return false;
      }
    }

    isValidDate (date: Date): boolean {
      return !isNaN(date.getTime());
    }
}

@Component({
  selector: 'app-pdf-dialog',
  template: `
    <div class="column">
      <div>
        <mat-form-field>
          <mat-select placeholder="Paper Size" [(ngModel)]="size">
            <mat-option *ngFor="let option of sizeOptions" [value]="option.value">
              {{ option.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row">
        <button mat-raised-button (click)="cancel()">Cancel</button>
        <button mat-raised-button (click)="save()">Save</button>
      </div>
    </div>
  `
})
export class PDFDialogComponent {
  size: string;
  sizeOptions = [
    {value: 'letter', viewValue: 'Letter/ANSI A'},
    {value: 'ledger', viewValue: 'Ledger/ANSI B'},
    {value: 'd', viewValue: 'ANSI D'},
    {value: 'e', viewValue: 'ANSI E'}
  ];

  constructor(
    public dialogRef: MatDialogRef<SettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

    cancel() {
      this.dialogRef.close();
    }

    save() {
      this.dialogRef.close();
      return {paperSize: this.size};
    }
}
