import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService, UserService } from '@app/core';
import { take } from 'rxjs/operators';

interface DialogData { event: any }

@Component({
  selector: 'app-picture-dialog',
  templateUrl: './picture-dialog.component.html',
  styleUrls: ['./picture-dialog.component.scss']
})
export class PictureDialogComponent implements OnInit {

  imageChangedEvent;
  croppedImage;
  cropperReady = false;
  loading = false;

  constructor(
    private auth: AuthService,
    private userService: UserService,
    private dialogRef: MatDialogRef<PictureDialogComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: DialogData
  ) { }

  ngOnInit(): void {
    this.imageChangedEvent = this.data.event;
  }

  savePicture(): void {
    this.loading = true;
    const image = this.base64ToFile(this.croppedImage);
    this.userService.currentUser.pipe(take(1)).subscribe(user => {
      this.auth.updateProfilePicture(image as File, user.id).subscribe(() => {
        this.loading = false;
        this.dialogRef.close('profile.png');
      }, this.error);
    });
  }

  error = (err): void => {
    const msg = err.message || err;
    this.snackBar.open(msg, null, {
      duration: 6000,
      panelClass: 'error'
    });

    this.dialogRef.close();
  }

  base64ToFile(image): Blob {
    const split = image.split(',');
    const type = split[0].replace('data:', '').replace(';base64', '');
    const byteString = atob(split[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], {type});
  }
}
