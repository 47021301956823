import {
  GenericNotification,
  GenericRule,
  RuleType,
} from '../alert-manager/alert-manager.models';
import * as moment from 'moment-timezone';
import { FeatureCollection } from 'geojson';

export interface IconStyles {
  [key: string]: IconStyle;
}

export interface IconStyle {
  icon?: string;
  color?: string;
  svg?: boolean;
}

export interface TypeStyle extends RuleType, IconStyle {}

export interface TypeStyles {
  [key: string]: TypeStyle;
}

export interface AlertThreshold {
  value: number | string;
  prob: number;
  type: 'float' | 'string' | 'probability';
  unit: string;
}

export interface AlertTime {
  time: number;
  big: string;
  small: string;
}

export interface GenericAlert {
  alertId: string;
  activation: [AlertTime, AlertTime];
  geoJSON: FeatureCollection;
  notifications: GenericNotification[];
  rule: AlertRule;

  forecastTime?: AlertTime;
}

export interface AlertRule {
  ruleId: string;
  name: string;
  description?: string;
  ruleType: string;
  importance: number;
  threshold: number | string;
  unit: string;
  thresholdDescription: string;
  probability?: number;
  sourceDescription: string;
  duration: number;

}

export interface AlertModelAPI {
  project: string;
  alertId: string;
  alertStart: number;
  alertEnd?: number;
  acknowledgedBy?: string;
  acknowledgedTime?: number;
  rule: GenericRule;
  notifications: GenericNotification[];

  forecastTime?: number;
}

export class AlertMaker {
  static create(event: AlertModelAPI, tz: string): GenericAlert {
    const threshold = this.getThreshold(event.rule);
    const unit = event.rule.meta.ruleType === 'Complex' ? 'rule(s)' : event.rule.unit;
    return {
      alertId: event.alertId,
      activation: [
        this.getAlertTime(event.alertStart, tz),
        this.getAlertTime(event.alertEnd, tz),
      ],
      geoJSON: this.getGeoJSON(event.notifications),
      notifications: event.notifications,
      rule: {
        ruleId: event.rule.meta.id,
        name: event.rule.meta.name,
        description: event.rule.meta.description,
        ruleType: event.rule.meta.ruleType,
        importance: event.rule.meta.priority,
        threshold,
        probability: event.rule.probabilityThreshold,
        unit: unit,
        thresholdDescription: this.getThresholdDescription(
          event.rule,
          threshold,
          unit
        ),
        sourceDescription: this.getSourceDescription(event),
        duration: event.rule.duration,
      },
      forecastTime: this.getAlertTime(event.forecastTime, tz)
    };
  }

  private static getSourceDescription(event: AlertModelAPI): string {
    const featureLength = (event.rule.idList || event.rule.points).length;
    const description = {
      any: `At least one value in ${featureLength} locations crossed the threshold.`,
      all: `All values in ${featureLength} locations crossed the threshold.`,
      avg: `An average of the values in ${featureLength} locations crossed the threshold.`,
    };
    return featureLength > 1 && event.rule.aggregation
      ? description[event.rule.aggregation.toLowerCase()]
      : '';
  }

  private static getGeoJSON(notifs: GenericNotification[]): FeatureCollection {
    const mapsNotif = notifs.find(
      (notif) => notif.meta.notificationType === 'Map',
    );

    return mapsNotif ? JSON.parse(mapsNotif.geoJSON[0]) : null;
  }

  private static getThreshold(rule: GenericRule): number | string {
    switch (rule.meta.ruleType) {
      case 'WatchpointPreset':
        return rule.thresholdString;
      case 'DDF':
        return rule.frequencyLabel;
      case 'PQPF':
        return rule.precipitationMinimum;
      default:
        return rule.threshold;
    }
  }

  private static getThresholdDescription(
    rule: GenericRule,
    threshold: number | string,
    unit: string = rule.unit
  ): string {
    let message: string;
    const duration = rule.duration
      ? moment.duration(rule.duration, 'millisecond').humanize()
      : 'Any';
    switch (rule.meta.ruleType) {
      case 'WatchpointPreset':
        message = `Preset: ${threshold}`;
        break;
      case 'DDF':
        message = `${threshold} return (${duration})`;
        break;
      case 'POP':
        message = `${rule.probabilityThreshold}% chance`;
        break;
      case 'PQPF':
        message = `${rule.probabilityThreshold}% chance of ${threshold} ${unit}`;
        break;
      default:
        message = `${threshold} ${unit}${
          rule.duration ? ' (' + duration + ')' : ''
        }`;
    }

    return message
      .replace(/(minutes|minute)/, 'min')
      .replace(/(hours|hour)/, 'hr');
  }

  private static getAlertTime(time: number, tz: string): AlertTime {
    if (!time) return;
    const big = this.getBigTime(time, tz);
    const small = this.getSmallTime(time, tz);
    return { time, big, small };
  }

  private static getBigTime(time: number, tz: string): string {
    if (!time) return;
    return moment(time).tz(tz).format('YYYY-MM-DD HH:mm');
  }

  private static getSmallTime(time: number, tz: string): string {
    if (!time) return;
    const year = moment().tz(tz).year();
    const month = moment().tz(tz).month();
    const day = moment().tz(tz).date();
    const mTime = moment(time).tz(tz);

    if (mTime.year() < year) {
      return mTime.format('YYYY/MM/DD');
    }
    if (mTime.month() < month || mTime.date() < day) {
      return mTime.format('DD MMM');
    }

    return mTime.format('HH:mm');
  }
}
