import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Time } from '@app/shared/models';
import { GraphService } from './graph.service';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss'],
})
export class GraphComponent implements OnInit {
  id: string;
  attributes: string[];
  lngLat: {
    lng: number;
    lat: number;
  };
  timestamp = Date.now();
  times: Time;
  symbolicName: string;
  type: string;
  name: string;
  feed: string;
  displayId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private service: GraphService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(
      (data: { [key: string]: string }) => {
        const [start, end, rt] = data.r.split(',').map((v) => +v);
        this.id = data.p;
        this.name = data.n;
        this.feed = data.f;
        this.displayId = data.id;
        this.times = {
          start: new Date(start),
          end: new Date(end),
          realtime: !!rt,
        };
        const lngLat = data.l?.split(',').map((l) => +l);
        this.lngLat = lngLat !== undefined ? {lng: lngLat[0], lat: lngLat[1]} : undefined;
        this.attributes = data.as?.split(',') || [];
        this.symbolicName = data.sym;
        this.type = this.service.chartTypes[+data.ty]; // set last for async template binding
      }
    );
  }
}
