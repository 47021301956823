<div class="user-container" fxLayout fxLayoutGap="16px">
  <div class="avatar-container">
    <app-avatar></app-avatar>
  </div>
  <div class="two-line" fxFlex fxLayout="column" fxLayoutAlign="center start">
    {{user?.getFullName()}}
    <span matTooltip="Company Name" class="sub">{{user?.company.name}}</span>
  </div>
</div>
<mat-divider></mat-divider>
<div class="project-container">
  <div class="two-line">
    <span class="sub">
      {{
        user?.company.projects.length > 1 ? 'Current ' : ''
      }}Project Name
    </span>
    {{project?.name}}
  </div>
  <div class="two-line">
    <span class="sub">Location</span>
    {{project?.location}}
  </div>
  <div class="two-line">
    <span class="sub">Time zone</span>
    {{project?.timezone}}
  </div>
  <div class="two-line" *ngIf="project?.description">
    <span class="sub">Description</span>
    {{project?.description}}
  </div>
</div>
<div *ngIf="resourceGroups?.length" class="profile-elements">
  <h4>Links &amp; Resources</h4>
  <mat-nav-list [attr.dense]="resourceGroups.length > 3 ? true : null">
    <ng-container *ngFor="let group of resourceGroups">
      <mat-list-item *ngIf="group.resources.length > 9" (click)="openGroupDialog(group)">
        <mat-icon matListIcon>folder</mat-icon>
        {{ group.groupName }}
      </mat-list-item>
      <ng-container *ngIf="group.resources.length < 10">
        <mat-list-item
            [matMenuTriggerFor]="menu">
          <mat-icon matListIcon>folder</mat-icon>
          {{ group.groupName }}
        </mat-list-item>
        <mat-menu #menu="matMenu">
          <a
              mat-menu-item
              *ngFor="let resource of group.resources"
              [href]="resource.url"
              [matTooltip]="resource.tooltip"
              matTooltipPosition="right"
              matTooltipShowDelay="500"
              [target]="resource.type === 'file' ? '_self' : '_blank'">
            <mat-icon matListIcon>{{resource.type === 'file' ? 'get_app' : 'launch'}}</mat-icon>
            {{resource.name}}
          </a>
        </mat-menu>
      </ng-container>
    </ng-container>
  </mat-nav-list>
</div>
<div fxFlex></div>
<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
